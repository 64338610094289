import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as dateFns from 'date-fns';
import { IMember, MemberService } from '../../services/member.service';

@Component({
  selector: 'app-upcoming-event-card',
  template: `
  <div class="upcoming-event-card-wrapper d-flex" [ngClass]="{}">
    <div class="days-left-wrapper d-flex">
      <img *ngIf="todaysDate && (anniversaryType === 'birthday')" src="../../../assets/birthday-cake-solid.svg" alt="balloon">
      <img *ngIf="todaysDate && (anniversaryType === 'workdayAnniversary')" src="../../../assets/balloon.svg" alt="balloon">
      <img *ngIf="todaysDate && (anniversaryType === 'customAnniversary')" src="../../../assets/briefcase-solid.svg" alt="balloon">
      <p class="days-left"> {{ daysLeft !== '365' ? daysLeft : null }}</p>
      <p class="text-small" *ngIf="!todaysDate">days</p>
      <p class="text-small" *ngIf="todaysDate">TODAY</p>
    </div>
    <div class="event-wrapper">
      <div class="date-wrapper d-flex">
        <span>{{ anniversaryDate }}</span>
      </div>
      <div class="name-wrapper">
        <span>{{ name }}</span>
      </div>
      <div class="type-wrapper">
        <span>{{ anniversaryTypeText }}</span>
      </div>
    </div>
  </div>
  `,
  styles: [`
    img {
      max-height: 50%;
      padding-top: .5rem;
    }

    p {
      margin: 0;
    }

    .upcoming-event-card-wrapper {
      max-width: 20rem;
      margin: 1rem 0;
      text-align: center;
      height: 5rem;
      align-items: center;
      border-radius: 0.25rem;
    }

    .days-left-wrapper {
      min-width: 4.6rem;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      color: white;
    }

    :host.green-header .upcoming-event-card-wrapper {
      border: 1px solid var(--yearsyLightBlue);
    }

    :host.purple-header  .upcoming-event-card-wrapper {
      border: 1px solid var(--yearsyLightPink);
    }

    :host.blue-header .upcoming-event-card-wrapper {
      border: 1px solid var(--yearsyDarkBlue);
    }

    :host.green-header .days-left-wrapper {
      background-color: var(--yearsyLightBlue);
    }

    :host.purple-header .days-left-wrapper {
      background-color: var(--yearsyLightPink);
    }

    :host.blue-header .days-left-wrapper {
      background-color: var(--yearsyDarkBlue);
    }

    .days-left {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .event-wrapper {
      color: grey;
      margin: 0 auto;
    }

    .date-wrapper {
      font-size: 0.75rem;
      padding: 0 0 .5rem;
    }

    .date-wrapper span {
      flex-grow: 1;
    }

    .name-wrapper {
      color: #000;
    }

    .type-wrapper {
      font-size: 0.8rem;
    }
  `]
})
export class UpcomingEventCardComponent implements OnChanges {
  public name: string;
  public anniversaryTypeText: string;
  public anniversaryDate: string;
  public daysLeft: any;
  public todaysDate: boolean;

  @Input() teamMember: IMember;
  @Input() anniversaryType: any;

  constructor(private member: MemberService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['teamMember'] && this.teamMember) {
      this.anniversaryDate = this.convertDateToCorrectFormat(this.teamMember[this.anniversaryType]);
      this.daysLeft = this.member.getDifferenceInDays(this.teamMember[this.anniversaryType]).toString();
    }
    if (this.daysLeft === '365') {
      this.todaysDate = true;
    } else {
      this.todaysDate = false;
    }

    this.anniversaryTypeText = this.member.generateUpcomingAnniversaryText(this.teamMember, this.anniversaryType);
    this.name = this.teamMember.name;
  }

  convertDateToCorrectFormat(date: Date): string {
    return `${dateFns.format(date, 'D MMMM')}`;
  }
}
