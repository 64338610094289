import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Every } from '../../helpers/every.pipe';
import { IsIndeterminate } from '../../helpers/is-indeterminate.pipe';
import { FacebookSDKService } from '../../services/facebook-sdk-service';
import { INotificationRecipient, NotificationRecipientService } from '../../services/notification-recipient.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-add-recipient',
  template: `
    <form [formGroup]="recipientForm" class="pure-form-stacked">
      <div class="form-row">
        <div class="input-wrapper">
          <mat-form-field hintLabel="Min 5 characters" *ngIf="(!index && !saved && index !== 0) || editMode" >
            <mat-label>Full Name</mat-label>
            <input type="text" class="form-control" id="fullName" #fullName placeholder="Name" formControlName="name" minlength="5" required matInput >
          </mat-form-field>
          <p class="form-control text-small" *ngIf="(index || saved || index === 0) && !editMode"> {{ recipient.name }} </p>
        </div>
        <div class="input-wrapper">
          <mat-form-field hintLabel="hello@exampel.com" *ngIf="(!index && !saved && index !== 0) || editMode" >
            <mat-label>Email</mat-label>
            <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email" required matInput >
          </mat-form-field>
          <p class="form-control text-small" *ngIf="(index || saved || index === 0) && !editMode"> {{ recipient.email }} </p>
        </div>
        <div class="row">
          <div *ngIf="!editMode && !creatingNewRecipient" class="notification-wrapper all-toggle">
            <p class="text-small">Birthdays</p>
            <mat-checkbox (change)="toggleCheckBoxGroup(recipient.notificationTimes.birthday)" [indeterminate]="recipient.notificationTimes.birthday | IsIndeterminate"
            [checked]="recipient.notificationTimes.birthday | Every"></mat-checkbox>
          </div>
          <div *ngIf="!editMode && !creatingNewRecipient" class="notification-wrapper all-toggle">
            <p class="text-small">Workiversary</p>
            <mat-checkbox (change)="toggleCheckBoxGroup(recipient.notificationTimes.workdayAnniversary)" [indeterminate]="recipient.notificationTimes.workdayAnniversary | IsIndeterminate"
            [checked]="recipient.notificationTimes.workdayAnniversary | Every"></mat-checkbox>
          </div>
          <div *ngIf="!editMode && !creatingNewRecipient" class="notification-wrapper all-toggle">
            <p class="text-small">Probation</p>
            <mat-checkbox (change)="toggleCheckBoxGroup(recipient.notificationTimes.customAnniversary)" [indeterminate]="recipient.notificationTimes.customAnniversary | IsIndeterminate"
            [checked]="recipient.notificationTimes.customAnniversary | Every"></mat-checkbox>
          </div>
        </div>
        <div class="edit-wrapper">
          <div *ngIf="index === 0 && userRef"
            class="fb-messenger-checkbox"
            [attr.origin]="locationOrigin"
            [attr.page_id]="pageId"
            [attr.messenger_app_id]="appId"
            [attr.user_ref]="userRef"
            allow_login="true"
            size="small"
            skin="light"
            center_align="true">
          </div>
          <img *ngIf="index === 0 && !userRef" (click)="showMessegeOptOutConfirmation = true" class="messenger-opt-out" src="../../../assets/messenger-optedin.png" alt="edit">
          <div class="list-icon">
            <img *ngIf="!editMode && !creatingNewRecipient" (click)="editMode = true;" src="../../../assets/edit-vector.svg" alt="edit">
            <img *ngIf="(!index && !saved && index !== 0) || editMode || creatingNewRecipient" class="edit-button" src="../../../assets/save.svg" alt="save" (click)="editRecipient()">
          </div>
          <div class="list-icon">
            <img *ngIf="(!editMode || creatingNewRecipient) && index !== 0" (click)="modalVisible = true" src="../../../assets/trashcan-vector.svg" alt="remove">
            <img *ngIf="editMode" (click)="editMode = false;" class="edit-button" src="../../../assets/remove.svg" alt="cancel">
          </div>
        </div>
      </div>
      <app-confirmation-dialog [title]="'Are you sure you want to delete this recipient?'" [prompt]="''" [modalVisible]="modalVisible"
        [confirmButtonLabel]="'Delete'" (confirmed)="onConfirmed($event)"></app-confirmation-dialog>
      <app-confirmation-dialog [title]="'Are you sure you want to receive messages on Facebook Messenger?'" [prompt]="''" [modalVisible]="showMessegeOptInConfirmation"
        [confirmButtonLabel]="'Yes'" (confirmed)="confirmMessengerOptIn($event)"></app-confirmation-dialog>
      <app-confirmation-dialog [title]="'Are you sure you want to stop to receive messages on Facebook Messenger?'" [prompt]="''" [modalVisible]="showMessegeOptOutConfirmation"
        [confirmButtonLabel]="'Yes'" (confirmed)="handleMessengerOptOut($event)"></app-confirmation-dialog>
    </form>
    <div *ngIf="editMode || creatingNewRecipient" class="row">
      <div class="notification-wrapper">
        <h4>Birthdays</h4>
        <div class="check-boxes-wrapper">
          <div class="check-box">
            <mat-checkbox class="example-margin" [(ngModel)]="recipient.notificationTimes.birthday[0]">Same day</mat-checkbox>
          </div>
          <div class="check-box">
            <mat-checkbox class="example-margin" [(ngModel)]="recipient.notificationTimes.birthday[86400000]">Day before</mat-checkbox>
          </div>
          <div class="check-box">
            <mat-checkbox class="example-margin" [(ngModel)]="recipient.notificationTimes.birthday[604800000]">Week before</mat-checkbox>
          </div>
        </div>
      </div>
      <div class="notification-wrapper">
        <h4>Workiversary</h4>
        <div class="check-boxes-wrapper">
          <mat-checkbox class="example-margin"  [(ngModel)]="recipient.notificationTimes.workdayAnniversary[0]">Same day</mat-checkbox>
          </div>
          <div class="check-box">
          <mat-checkbox class="example-margin"  [(ngModel)]="recipient.notificationTimes.workdayAnniversary[86400000]">Day before</mat-checkbox>
          </div>
          <div class="check-box">
          <mat-checkbox class="example-margin"  [(ngModel)]="recipient.notificationTimes.workdayAnniversary[604800000]">Week before</mat-checkbox>
        </div>
      </div>
      <div class="notification-wrapper">
        <h4>Probation Period</h4>
        <div class="check-boxes-wrapper">
          <mat-checkbox class="example-margin"  [(ngModel)]="recipient.notificationTimes.customAnniversary[1209600000]">Two weeks</mat-checkbox>
          </div>
          <div class="check-box">
          <mat-checkbox class="example-margin"  [(ngModel)]="recipient.notificationTimes.customAnniversary[2419200000]">One months</mat-checkbox>
          </div>
          <div class="check-box">
          <mat-checkbox class="example-margin"  [(ngModel)]="recipient.notificationTimes.customAnniversary[4838400000]">Two months</mat-checkbox>
        </div>
      </div>
    </div>
    <hr>
  `,
  styles: [`
    .row {
      flex-direction: row;
    }

    .all-toggle {
      margin: .5rem;
    }

    .all-toggle p{
      font-size: 0.75rem;
      margin-bottom: 0;
      text-align: center;
    }

    .list-icon {
      z-index: 2;
    }

    .fb-messenger-checkbox {
      width: 230px;
    }

    img.messenger-opt-out {
      width: 4rem;
      height: auto;
      cursor: pointer;
      margin-right: 0.5rem;
    }

    ::ng-deep .all-toggle .mat-checkbox-inner-container {
      display: block !important;
      margin: auto !important;
    }

    .input-wrapper {
      margin: .5rem 0;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }

    .edit-wrapper {
      justify-content: flex-end;
    }

    .edit-button {
      cursor: pointer;
      height: 1.5rem;
    }

    @media screen and (min-width: 992px) {
      .notification-wrapper {
        flex: 1;
      }

      .row {
        flex-wrap: nowrap
      }

      .form-row {
        display: flex;
        flex-direction: row;
      }

      .input-wrapper {
        width: 13rem;
      }

      .edit-wrapper {
        margin-left: auto;
      }

      .all-toggle {
        margin: .5rem 0.5rem;
      }
    }

    @media screen and (min-width: 1150px) {
      .all-toggle {
        margin: .5rem 0.5rem;
      }
    }
  `]
})
export class AddRecipientComponent implements OnChanges, OnInit {
  public recipientForm: FormGroup;
  public submitted: boolean;
  public saved: boolean;
  public editMode: boolean;
  public fetchedId: string;
  public modalVisible: boolean;
  public creatingNewRecipient: boolean;
  public locationOrigin: any;
  public userRef: string | boolean;
  public appId: string;
  public pageId: string;
  public showMessegeOptInConfirmation: boolean;
  public showMessegeOptOutConfirmation: boolean;
  public userId: string;

  private recipientFormValues: INotificationRecipient;
  private notificationTimeFactoryObject: any;

  @Input() public recipient: INotificationRecipient;
  @Input() public index: number;

  @Output() public removeRecipientEvent = new EventEmitter<{ name: string; id: string }>();
  @Output() public newRecipientSavedEvent = new EventEmitter<boolean>();

  constructor(private notificationRecipient: NotificationRecipientService,
              private formBuilder: FormBuilder, private snackbar: SnackbarService,
              private every: Every, private isIndeterminant: IsIndeterminate,
              private FBSDK: FacebookSDKService, private user: UserService) {
    this.submitted = false;
    this.saved = false;
    this.editMode = false;
    this.modalVisible = false;

    this.recipientForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.email
      ]]
    });

    this.notificationTimeFactoryObject = {
      birthday: {
        0: true,
        86400000: false,
        604800000: false
      },
      workdayAnniversary: {
        0: true,
        86400000: false,
        604800000: false
      },
      customAnniversary: {
        1209600000: true,
        2419200000: false,
        4838400000: false
      }
    };
  }

  ngOnInit() {
    if (!this.recipient) {
      this.creatingNewRecipient = true;
      this.recipient = {
        name: '',
        email: '',
        _id: '',
        userId: '',
        notificationTimes: { ...this.notificationTimeFactoryObject }
      };
    }

    // Only for first notification setting
    if (this.index === 0) {
      this.user.getUserData().then((userData) => {
        this.userId = userData._id;
        this.locationOrigin = location.origin;
        this.appId = this.FBSDK.getAppId();
        this.pageId = this.FBSDK.getPageId();

        if (userData.facebook && (!userData.facebook.messenger || !userData.facebook.messenger.optIn)) {
          this.user.getUserRef().then((userRef) => {
            // for rendering button, userRef needs to be unique per user and render
            if (userRef) {
              this.userRef = userRef;
              this.FBSDK.get().then(this.setUpFBSDKEventListener).catch(console.error);
            }
          }).catch();
        } else {
          this.userRef = false;
        }
      }).catch(console.error);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['recipient'] && this.recipient) {
      this.recipientForm.patchValue({ name: this.recipient.name });
      this.recipientForm.patchValue({ email: this.recipient.email });
      this.saved = false;
    }
  }

  onConfirmed(confirmed: boolean) {
    this.modalVisible = false;
    if (confirmed) {
      this.saved = false;
      const recipientName = this.recipient ? this.recipient.name : null;
      const recipientId = this.recipient ? this.recipient._id : this.fetchedId;
      const recipient = { name: recipientName, id: recipientId };
      this.removeRecipientEvent.emit(recipient);
    }
  }

  confirmMessengerOptIn(confirmed: boolean) {
    this.showMessegeOptInConfirmation = false;
    if (confirmed) {
      this.FBSDK.trackEvent('MessengerCheckboxUserConfirmation', { user_ref: this.userRef });
      this.userRef = false;
    } else {
      // Reset button
      this.user.getUserRef().then(userRef => {
        this.userRef = userRef;
        this.FBSDK.reparseDOM(true);
      }).catch(error => {
        if (error.name === 'SDKNotInitialized') {
          this.FBSDK.get().then(this.setUpFBSDKEventListener).catch(console.error);
        } else {
          this.userRef = false;
        }
      });
    }
  }

  handleMessengerOptOut(confirmed: boolean) {
    this.showMessegeOptOutConfirmation = false;
    if (confirmed) {
      const recipientId = this.recipient ? this.recipient._id : this.fetchedId;

      const optOutNotificationPromise = this.notificationRecipient.optOutMessenger(recipientId);
      const optOutUserPromise = this.user.updateMyUserData({
        facebook: {
          messenger: {
            optIn: false
          }
        }
      });

      Promise.all([optOutNotificationPromise, optOutUserPromise]).then(([notificationResponse, userResponse]) => {
          if (notificationResponse.successful && userResponse.successful) {
            this.user.getUserRef().then(userRef => {
              this.userRef = userRef;
              this.FBSDK.reparseDOM(true);
            }).catch(error => {
              if (error.name === 'SDKNotInitialized') {
                this.FBSDK.get().then(this.setUpFBSDKEventListener).catch(console.error);
              } else {
                this.userRef = false;
              }
            });
          }
      }).catch(console.error);
    }
  }

  editRecipient() {
    this.saved = false;
    const recipientId = this.recipient ? this.recipient._id : this.fetchedId;
    if (!this.editMode && recipientId) {
      this.saved = false;
      return this.editMode = true;
    }
    this.saveRecipientData(recipientId);
  }

  toggleCheckBoxGroup(objectToToggle: any): void {
    let toggleValue: boolean;

    if (this.isIndeterminant.transform(objectToToggle)) {
      toggleValue = true;
    } else if (this.every.transform(objectToToggle)) {
      toggleValue = false;
    } else {
      toggleValue = true;
    }

    Object.keys(objectToToggle).forEach((key) => {
      objectToToggle[key] = toggleValue;
    });

    this.saveRecipientData(this.recipient._id);
  }

  setUpFBSDKEventListener = (sdk) => {
    sdk.Event.subscribe('messenger_checkbox', (e) => {
      if (e.event === 'rendered') {
        console.log('Checkbox plugin was rendered');
      } else if (e.event === 'checkbox' && e.state === 'checked') {
        this.showMessegeOptInConfirmation = true;
      } else if (e.event === 'not_you') {
        console.log('User clicked \'not you\'');
      } else if (e.event === 'hidden') {
        console.log('Plugin was hidden');
      }
    });
  }

  private saveRecipientData(recipientId: string) {
    this.submitted = true;
    if (this.recipientForm.valid) {
      this.recipientFormValues = this.recipientForm.value;
      this.recipientFormValues['id'] = recipientId;
      this.recipientFormValues.notificationTimes = this.recipient.notificationTimes;

      this.notificationRecipient.setNotificationRecipient(this.recipientFormValues).then((response) => {
        if (response.updated) {
          this.snackbar.message = `${this.recipientFormValues.name}'s information was updated`;
          this.fetchedId = response.id;

          if (!this.recipient) {
            this.recipient = {
              name: '',
              email: '',
              _id: '',
              userId: '',
              notificationTimes: { ...this.notificationTimeFactoryObject }
            };
          }

          this.recipient.name = response.name;
          this.recipient.email = response.email;
          this.recipient._id = response.id;
          this.recipient.userId = response.userId;
          this.recipient.notificationTimes = response.notificationTimes;

          if (!this.recipientFormValues['id']) {
            this.creatingNewRecipient = false;
            this.newRecipientSavedEvent.emit(true);
            this.snackbar.message = `${this.recipientFormValues.name} was added to your reciepient list`;
          }

          this.saved = true;
          this.editMode = false;
        } else {
          this.editMode = false;
          this.snackbar.message = response.message;
        }
        this.snackbar.openSnackBar();
      }).catch();
    } else {
      this.saved = false;
    }
  }
}
