import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../../app.config';
import { ConfirmService } from '../../services/confirm.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-list',
  template: `
    <div class="page-wrapper">
      <div class="label">
        <form class="search-form">
          <fieldset>
            <input type="text" #keyword (keyup)="$event.target.value ? '':  searchForUser('')" placeholder="Company, name or email">
            <button class="primary-btn" (click)="searchForUser(keyword.value)">Search</button>
            <h3 *ngIf="noSearchResults">No search results</h3>
          </fieldset>
        </form>
      </div>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr class="d-flex">
              <th class="table-cell">
                <h3 (click)="sortUsers('name')">Name </h3>
                <span *ngIf="orderByField === 'name'">
                  <span class="sort-up sort-arrow" *ngIf="sort === 'ascending'"></span>
                  <span class="sort-down sort-arrow" *ngIf="sort === 'descending'"></span>
                </span>
              </th>
              <th class="table-cell">
                <h3 (click)="sortUsers('email')">Email</h3>
                <span *ngIf="orderByField === 'email'">
                  <span class="sort-up sort-arrow" *ngIf="sort === 'ascending'"></span>
                  <span class="sort-down sort-arrow" *ngIf="sort === 'descending'"></span>
                </span>
              </th>
              <th class="table-cell">
                <h3>Roles</h3>
              </th>
              <th class="table-cell">
                <h3 (click)="sortUsers('teamMembers')">Team members added</h3>
                <span *ngIf="orderByField === 'teamMembers'">
                  <span class="sort-up sort-arrow" *ngIf="sort === 'ascending'"></span>
                  <span class="sort-down sort-arrow" *ngIf="sort === 'descending'"></span>
                </span>
              </th>
              <th class="table-cell">
                <h3 (click)="sortUsers('createdAt')">Created at</h3>
                <span *ngIf="orderByField === 'createdAt'">
                  <span class="sort-up sort-arrow" *ngIf="sort === 'ascending'"></span>
                  <span class="sort-down sort-arrow" *ngIf="sort === 'descending'"></span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="d-flex" *ngFor="let user of pagedUsers">
              <td class="table-cell"><p>{{ user.name }}</p></td>
              <td class="table-cell"><p>{{ user.email }}</p></td>
              <td class="table-cell user-roles">
                <span *ngFor="let role of avalibleRoles; let i = index">
                  <span *ngIf="i > 0 && user.roles[role]">, </span>
                  <p class="user-role-paragraph" *ngIf="user.roles[role]">{{ role | titlecase }}</p>
                </span>
              </td>
              <td class="table-cell"><p>{{ user.teamMembers.length }}</p></td>
              <td class="table-cell"><p>{{ user.createdAt.split('T')[0] }}</p></td>
              <td class="table-cell edit-wrapper">
                <div class="list-icon"  (click)="editUser(user)">
                  <img src="../../assets/edit-vector.svg">
                </div>
                <div class="list-icon" *ngIf="isSuperUser === true" (click)="removeUser(user)">
                  <img src="../../assets/trashcan-vector.svg">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="paginator-wrapper">
        <div class="paginator">
          <div (click)="previous()" class="previous">
            <span><a></a></span>
          </div>
          <div class="current">
            <span class="text-small">Page {{ this.page }}</span>
          </div>
          <div (click)="next()" class="next">
            <a></a>
          </div>
        </div>
        <span class="total-pages text-small">of {{ totalNumberOfPages }}</span>
      </div>
      <app-spinner *ngIf="isLoading"></app-spinner>
    </div>
    <app-confirmation-modal></app-confirmation-modal>
  `,
  styleUrls: ['./admin.component.css']
})
export class UserListComponent implements OnInit {
  public error: string;
  public pagedUsers: Array<any>;
  public totalNumberOfPages: number;
  public page: number;
  public isLoading: boolean;
  public orderByField: any;
  public sort: any;
  public avalibleRoles: Array<string>;
  public noSearchResults: boolean;

  public removeModalShow: boolean;
  public modalTitle: string;
  public removeModalPrompt: string;
  public modalCallback: any;
  public isSuperUser: boolean;

  constructor(private http: HttpClient, private userService: UserService, private confirmService: ConfirmService) {
    this.removeModalShow = false;
    this.page = 1;
    this.totalNumberOfPages = 1;
    this.orderByField = 'company';
    this.sort = 'ascending';
    this.avalibleRoles = CONFIG.USER_ROLES;
    this.noSearchResults = false;
  }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.isLoading = true;
    this.userService.isSuperUser().then(isSuperUser => this.isSuperUser = isSuperUser).catch(() => this.isSuperUser = false);
    this.http.get(`${CONFIG.ENDPOINTS.USERS}?page=${this.page}&orderBy=${this.orderByField}&sort=${this.sort}`).toPromise().then((pagedUserResponse: { data: Array<any>; total: number}) => {
      this.pagedUsers = pagedUserResponse.data;
      this.totalNumberOfPages = Math.ceil(pagedUserResponse.total / 15);
      this.totalNumberOfPages = this.totalNumberOfPages > 0 ? this.totalNumberOfPages : 1;
      this.isLoading = false;
    }).catch(() => this.error = 'Could not get the users.');
  }

  next() {
    if (this.page >= this.totalNumberOfPages) {
      this.page = 1;
    } else {
      ++this.page;
    }
    this.getUsers();
  }

  previous() {
    if (this.page === 1) {
      this.page = this.totalNumberOfPages;
    } else {
      --this.page;
    }
    this.getUsers();
  }

  editUser(user: any) {
    const userDataToChange = this.getAuthorizedUserDataToUpdate(user);

    this.confirmService.activate(`Edit user (${userDataToChange.email.value})`, null, { ...JSON.parse(JSON.stringify(userDataToChange)) }).then(result => {
      if (result.confirmed) {
        const hasUpdatedUserFields = Object.keys(userDataToChange).filter((key) => {
          if (key === 'roles') {
            return userDataToChange[key].value.member !== result.changedData[key].value.member ||
              userDataToChange[key].value.admin !== result.changedData[key].value.admin ||
              userDataToChange[key].value.superuser !== result.changedData[key].value.superuser;
          } else {
            return userDataToChange[key].value !== result.changedData[key].value;
          }
        });
        if (hasUpdatedUserFields.length > 0) {
          this.userService.updateUserData(user._id, result.changedData).then(response => {
            if (response.successful) {
              this.getUsers();
            } else {
              this.error = 'Could not update user.';
            }
          }).catch(() => this.error = 'Could not update user.');
        }
      }
    }).catch();
  }

  private getAuthorizedUserDataToUpdate(user: any) {
    let userDataObject;
    if (this.isSuperUser === true) {
        userDataObject = this.userService.getUserDataObjToUpdate(user.name, user.email, user.roles);
      } else {
        userDataObject = this.userService.getUserDataObjToUpdate(user.name, user.email);
      }
    return userDataObject;
  }

  removeUser(user: any) {
    this.confirmService.activate('Delete', `Are you sure you want to delete ${user.name} (${user.email})?`).then(result => {
      if (result.confirmed) {
        this.http.delete(`${CONFIG.ENDPOINTS.USERS}/${user._id}`).toPromise().then((response: { deleted: Boolean }) => {
          if (response.deleted) {
            this.getUsers();
          }
        }).catch(() => this.error = 'Could not delete user.');
      }
    }).catch();
  }

  sortUsers(field) {
    if (this.orderByField === field && this.sort === 'ascending') {
      this.sort = 'descending';
    } else {
      this.sort = 'ascending';
    }
    this.orderByField = field;
    this.getUsers();
  }

  searchForUser(keyword: string) {
    if (keyword === '') {
      this.getUsers();
    } else {
      this.http.get(`${CONFIG.ENDPOINTS.USERS}/search?keyword=${keyword}`).toPromise().then((response: { data: Array<any> }) => {
        const users = response.data;
        if (users.length > 0) {
          this.pagedUsers = users;
          this.noSearchResults = false;
        } else {
          this.noSearchResults = true;
          this.getUsers();
        }
      }).catch();
    }
  }
}
