import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  template: `
    <div class="wrapper sign-up">
      <img class="logo" src="../../../assets/yearsy-logo.svg">
      <div class="form-wrapper">
        <h2>Sign Up</h2>
          <form [formGroup]="formGroup" (ngSubmit)="signUp(formGroup)" (keyup.enter)="signUp(formGroup)">
            <fieldset class="form-fieldset">
              <div class="form-input">
                <input type="text" required class="form-control" id="name" formControlName="name" autoComplete='name' placeholder="Full Name">
                <div class="error-message" *ngIf="(fullName.touched || fullName.dirty) && fullName.invalid">Full name must be atleast 4 letters</div>
              </div>
              <div class="form-input">
                <input type="email" required class="form-control" id="email" formControlName="email" placeholder="Email" autoComplete="email" (change)="emailExistsError = false">
                <div class="error-message" *ngIf="(email.touched || email.dirty) && email.invalid">Incorrect email format</div>
                <div class="error-message" *ngIf="submitted && emailExistsError">The email already exists</div>
              </div>
              <div class="form-input">
                <input type="password" class="form-control" id="password" formControlName="password" aria-placeholder="Password" autoComplete="new-password" placeholder="Password">
                <div class="error-message" *ngIf="(passwordControler.touched || passwordControler.dirty) && passwordControler.invalid">
                  Password must be at least 8 characters, contain a capital letter and a number.</div>
                </div>
              <div class="form-input">
                <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword" aria-placeholder="Confirm Password" autoComplete='new-password' placeholder="Confirm Password">
              </div>
              <div class="form-input">
                <input type="checkbox" class="form-control" id="terms" formControlName="terms">
                <label>I agree to the <a href="https://yearsy.com/terms.html" target="_blank">terms and conditions.</a></label>
                <div class="error-message" *ngIf="submitted && terms.invalid">You must accept the terms and conditions to use Yearsy</div>
              </div>
              <div class="error-message" *ngIf="submitted && formGroup.errors?.doesNotMatch">The passwords does not match</div>
              <button type="submit" class="button form-button">Sign Up</button>
            </fieldset>
          </form>
        </div>
        <p>Already have an account? <a class="sign-up-link" href="/login">Login</a></p>
      <div class="halfpage-background"></div>
    </div>
  `,
  styleUrls: ['../login/login.component.css']
})
export class SignupComponent {
  public username: string;
  public password: string;
  public name: string;
  public formGroup: FormGroup;
  public submitted: boolean;
  public emailExistsError: boolean;
  public passwordMatches: boolean;

  constructor(private user: UserService, private form: FormBuilder, private router: Router) {
    this.formGroup = this.form.group({
      name: ['', [
        Validators.required,
        Validators.minLength(4)
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$')
      ]],
      confirmPassword: ['', Validators.required],
      terms: ['', Validators.required]
    }, { validator: user.matchPassword });

    this.submitted = false;
    this.emailExistsError = false;
  }

  get fullName() {
    return this.formGroup.get('name');
  }

  get email() {
    return this.formGroup.get('email');
  }

  get passwordControler() {
    return this.formGroup.get('password');
  }

  get passwordConfirmControler() {
    return this.formGroup.get('passwordConfirm');
  }

  get terms() {
    return this.formGroup.get('terms');
  }

  signUp(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      const { name, email, password } = form.value;
      this.user.signUp(name, email, password).then((signedUp) => {
        if (signedUp) {
          return this.router.navigate(['/dashboard']).catch();
        }

        this.emailExistsError = true;
      }).catch();
    }
  }
}
