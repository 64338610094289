import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { DaysLeft } from './helpers/days-left.pipe';
import { Every } from './helpers/every.pipe';
import { IsIndeterminate } from './helpers/is-indeterminate.pipe';
import { ObjectKeys } from './helpers/object-keys.pipe';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { TeamGraphsComponent } from './components/team/team-graphs.component';
import { TeamListComponent } from './components/team/team-list.component';

import { FooterComponent } from './components/footer/footer.component';
import { TopBarComponent } from './components/topbar/topbar.component';

import { AddRecipientComponent } from './components/notifications/add-recipient.component';
import { NotificationSettingsComponent } from './components/notifications/notification-settings.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { RecipientComponent } from './components/notifications/recipient.component';

import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog.component';
import { SpinnerComponent } from './components/shared/spinner.component';

import { UpcomingEventCardComponent } from './components/dashboard/upcoming-event-card.component';
import { UpcomingEventsGraphComponent } from './components/dashboard/upcoming-events-graph.component';
import { UpcomingEventsComponent } from './components/dashboard/upcoming-events.component';
import { UserSettingsComponent } from './components/settings/user-settings.component';
import { InfoCardComponent } from './components/shared/info-card.component';
import { NewTeamMemberComponent } from './components/team/new-team-member.component';

import { UserListComponent } from './components/admin/user-list.component';

import { ResponseInterceptor } from './services/app.response.interceptor';

import { appRoutes } from './app.routes';

import { UserIsAdmin } from './guards/user-is-admin.guard';
import { UserIsNotLoggedIn } from './guards/user-is-not-logged-in.guard';

import { ConfirmationModalComponent } from './components/shared/confirmation-modal.component';
import { AverageAgeGraphComponent } from './components/team/average-age-graph.component';
import { EmployedStatsGraphComponent } from './components/team/employed-stats-graph.component';
import { EmploymentStartedGraphComponent } from './components/team/employment-started-graph.component';
import { TimeEmployedGraphComponent } from './components/team/time-employed-graph.component';
import { ConfirmService } from './services/confirm.service';
import { FacebookSDKService } from './services/facebook-sdk-service';

@NgModule({
  declarations: [
    AddRecipientComponent,
    AdminComponent,
    AppComponent,
    ConfirmationDialogComponent,
    DashboardComponent,
    LoginComponent,
    LayoutComponent,
    RecipientComponent,
    TeamListComponent,
    NotificationsComponent,
    NotificationSettingsComponent,
    TeamListComponent,
    TopBarComponent,
    SignupComponent,
    SpinnerComponent,
    UpcomingEventCardComponent,
    UpcomingEventsGraphComponent,
    UpcomingEventsComponent,
    UserSettingsComponent,
    NewTeamMemberComponent,
    AdminComponent,
    UserListComponent,
    ConfirmationModalComponent,
    ObjectKeys,
    DaysLeft,
    IsIndeterminate,
    Every,
    InfoCardComponent,
    FooterComponent,
    TeamGraphsComponent,
    AverageAgeGraphComponent,
    TimeEmployedGraphComponent,
    EmployedStatsGraphComponent,
    EmploymentStartedGraphComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MaterialModule,
    BrowserAnimationsModule,
    ChartsModule,
    RouterModule.forRoot(appRoutes),
    NgSelectModule
  ],
  providers: [
    UserIsNotLoggedIn,
    UserIsAdmin,
    ConfirmService,
    FacebookSDKService,
    IsIndeterminate,
    Every,
    {
      provide:
      HTTP_INTERCEPTORS,
      useClass:
      ResponseInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AddRecipientComponent]
})
export class AppModule { }
