import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
    public message: string;
    constructor(private snackBar: MatSnackBar) {
        this.message = '';
  }

  openSnackBar() {
    this.snackBar.open(this.message, 'OK', {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'snackbar-style'
    });
  }

}
