import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as dateFns from 'date-fns';
import * as XLSX from 'xlsx';
import { CONFIG } from '../../app.config';
import { IMember, MemberService } from '../../services/member.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-new-team-member',
  template: `
  <div class="new-team-member">
    <div class="add-team-member-top-wrapper">
      <h2>Add team member</h2>
      <div class="upload-wrapper">
        <label for="xlxs-upload">
          <img src="../../../assets/upload.svg" alt="upload">
        </label>
        <input id="xlxs-upload" type="file" (change)="uploadXLXS($event)" multiple="false">
      </div>
    </div>
    <div class="hr"></div>
    <div class="box-shadow-wrapper">
      <form class="row" #newTeamMemberForm="ngForm">
        <div class="input-wrapper">
          <mat-form-field hintLabel="Min 5 characters">
            <input [(ngModel)]="newTeamMember.name" required minlength="5" matInput id="fullName" name="fullName" placeholder="Full Name">
          </mat-form-field>
        </div>
        <div class="input-wrapper">
          <mat-form-field hintLabel="mm-dd-yyyy">
            <mat-label>Birthday</mat-label>
            <input [(ngModel)]="newTeamMember.birthday" id="birthday" name="birthday" matInput [matDatepicker]="birthday">
            <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
            <mat-datepicker #birthday></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="input-wrapper">
          <mat-form-field hintLabel="mm-dd-yyyy">
            <mat-label>Starting day</mat-label>
            <input [(ngModel)]="newTeamMember.workdayAnniversary" id="workDay" name="workDay" matInput [matDatepicker]="startingDay">
            <mat-datepicker-toggle matSuffix [for]="startingDay"></mat-datepicker-toggle>
            <mat-datepicker #startingDay color="primary"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="input-wrapper">
          <ng-select
            [items]="allDepartments"
            [addTag]="addDepartmentTag.bind(this)"
            [hideSelected]="true"
            multiple="true"
            bindLabel="name"
            [(ngModel)]="newTeamMember.department"
            name="department"
            placeholder="Department">
          </ng-select>
        </div>
        <div class="input-wrapper">
          <mat-form-field>
            <input [(ngModel)]="newTeamMember.email" minlength="5" matInput id="email" name="email" placeholder="employee@yearsy.com">
          </mat-form-field>
        </div>
        <div class="input-wrapper">
        <mat-form-field appearance="fill">
          <mat-label>Gender</mat-label>
          <mat-select [(value)]="newTeamMember.gender" required>
            <mat-option *ngFor="let gender of genders" [value]="gender.value">
              {{ gender.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="input-wrapper">
          <mat-form-field>
            <input [(ngModel)]="newTeamMember.location" minlength="5" matInput id="location" name="location" placeholder="Location">
          </mat-form-field>
        </div>
        <div class="edit-wrapper">
          <button class="primary-btn" [disabled]="!newTeamMemberForm.form.valid && newTeamMember.gender" (click)="saveTeamMember(newTeamMember)">save</button>
        </div>
      </form>
    </div>
  </div>
  `,
  styleUrls: ['./team-list.component.css']
})
export class NewTeamMemberComponent {
  public teamMembers: Array<any>;
  public newTeamMember: IMember;
  public genders: Array<any>;

  @ViewChild('newTeamMemberForm', { static: true }) newTeamMemberForm;
  @Output() onGetMembers: EventEmitter<any> = new EventEmitter();
  @Input() allDepartments: Array<any>;

  constructor(private member: MemberService, private snackbar: SnackbarService) {
    this.newTeamMember = {
      _id: null,
      name: '',
      team: '',
      department: [],
      birthday: null,
      workdayAnniversary: null,
      email: null,
      gender: null,
      location: null,
      probationPeriod: null
    };
    this.teamMembers = [];
    this.genders = CONFIG.GENDERS;
  }

  getMembers() {
    this.onGetMembers.emit(null);
  }

  addDepartmentTag(name) {
    return this.allDepartments.indexOf(name) < 0 ? { name: name, tag: true } : null;
  }

  removeValues() {
    this.newTeamMember = {
      _id: null,
      name: '',
      team: '',
      department: [],
      birthday: null,
      workdayAnniversary: null,
      email: null,
      gender: null,
      location: null,
      probationPeriod: null
    };
  }

  uploadXLXS(file: any) {
    const target: DataTransfer = <DataTransfer>(file.target);
    if (target.files.length !== 1) {
      alert(new Error('Cannot use multiple files'));
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const workBookString: string = e.target.result;
      const workBook: XLSX.WorkBook = XLSX.read(workBookString, { type: 'binary' });

      const workBookSheetName: string = workBook.SheetNames[0];
      const sheet: XLSX.WorkSheet = workBook.Sheets[workBookSheetName];

      const xlsxObjects = XLSX.utils.sheet_to_json(sheet, { raw: false });
      const parsedXLXS = xlsxObjects.map((newMemberData) => {
        let birthday;
        let workiversary;
        let customAnniversary;
        let employmentEndDate;

        if (typeof newMemberData['birthday'] === 'number' && typeof newMemberData['customAnniversary'] === 'number' && typeof newMemberData['workdayAnniversary'] && typeof newMemberData['employmentEndDate']) {
          birthday = this.decodeDATEVALUE(newMemberData['birthday']);
          workiversary = this.decodeDATEVALUE(newMemberData['workdayAnniversary']);
          customAnniversary = this.decodeDATEVALUE(newMemberData['customAnniversary']);
          employmentEndDate = this.decodeDATEVALUE(newMemberData['employmentEndDate']);
        } else {
          birthday = newMemberData['birthday'];
          workiversary = newMemberData['workdayAnniversary'];
          customAnniversary = newMemberData['customAnniversary'];
          employmentEndDate = newMemberData['employmentEndDate'];
        }

        newMemberData['birthday'] = dateFns.isDate(birthday) ? dateFns.format(birthday, 'YYYY-MM-DD') : birthday;
        newMemberData['workdayAnniversary'] = dateFns.isDate(workiversary) ? dateFns.format(workiversary, 'YYYY-MM-DD') : workiversary;
        newMemberData['customAnniversary'] = dateFns.isDate(customAnniversary) ? dateFns.format(customAnniversary, 'YYYY-MM-DD') : customAnniversary;
        newMemberData['employmentEndDate'] = dateFns.isDate(employmentEndDate) ? dateFns.format(employmentEndDate, 'YYYY-MM-DD') : employmentEndDate;

        newMemberData['department'] = this.makeDepartmentObjects(newMemberData['department']);

        return newMemberData;
      });

      const batchTeamMemberPromises = parsedXLXS.map((data: IMember) => {
        return this.saveTeamMember({
          _id: null,
          name: data['name'],
          birthday: data['birthday'],
          workdayAnniversary: data['workdayAnniversary'],
          employmentEndDate: data['employmentEndDate'],
          email: data['email'],
          department: data['department'],
          gender: data['gender'],
          location: data['location'],
          probationPeriod: data['probationPeriod']
        }, true);
      });

      Promise.all(batchTeamMemberPromises).then(() => {
        this.newTeamMemberForm.resetForm();
        this.getMembers();
        this.snackbar.message = `${batchTeamMemberPromises.length} team members has been added to your team`;
        this.snackbar.openSnackBar();
      }).catch(() => {
        this.snackbar.message = `There was an error uploading your team list`;
        this.snackbar.openSnackBar();
      });
    };
    reader.readAsBinaryString(target.files[0]);
  }

  saveTeamMember(member, batch: boolean = false): Promise<any> {
    if (batch) {
      return this.member.addMember(member);
    } else {
      this.removeValues();
      return this.member.addMember(member).then(() => {
        this.newTeamMemberForm.resetForm();
        this.getMembers();
        this.snackbar.message = `${member.name} has been added to your team`;
        this.snackbar.openSnackBar();
      }).catch();
    }
  }

  decodeDATEVALUE(dateValue: number): Date {
    return new Date((dateValue - 25569) * 86400 * 1000);
  }

  makeDepartmentObjects(departmentString: string) {
    if (departmentString !== null && departmentString !== undefined && departmentString !== '') {
      return departmentString.split(',').map(department => {
        return { name: department.trim(), tag: true };
      });
    } else {
      return [];
    }
  }
}
