import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { INotificationRecipient, NotificationRecipientService } from '../../services/notification-recipient.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AddRecipientComponent } from './add-recipient.component';

@Component({
  selector: 'app-recipient',
  template: `
  <h2>Notification recipients</h2>
  <div class="hr"></div>
  <div class="box-shadow-wrapper row">
    <div class="form-wrapper">
      <app-add-recipient *ngFor="let recipient of recipients; let i = index" #newRecipient [recipient]="recipient" [index]="i"
      (removeRecipientEvent)="removeRecipient($event)"></app-add-recipient>
    </div>
     <div class="button-wrapper" *ngIf="!createNewRecipient">
      <button type="submit" class="primary-btn" id="add-recipient-button" (click)="addNewRecipientComponent()">add new</button>
    </div>
  </div>
  `,
  styles: [`

  .box-shadow-wrapper {
    flex-direction: column;
  }
  .form-wrapper {
    padding: 1rem;
    max-width: 100%;
    box-sizing: border-box;
  }

  .button-wrapper {
    padding-left: 1rem;
  }
  `]
})
export class RecipientComponent implements OnDestroy, OnInit {
  public recipients: Array<INotificationRecipient>;
  public removeRecipientSubscription: any;
  createNewRecipient: boolean;
  newRecipientSavedSubscription: any;

  private recipientFactory: ComponentFactory<AddRecipientComponent>;

  @ViewChildren('newRecipient', { read: ViewContainerRef }) private recipientComponent: QueryList<ViewContainerRef>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private snackbar: SnackbarService, private notificationRecipient: NotificationRecipientService) {
    this.recipientFactory = this.componentFactoryResolver.resolveComponentFactory(AddRecipientComponent);
    this.recipients = [];
  }

  ngOnInit() {
    this.getRecipients();
  }

  getRecipients() {
    this.notificationRecipient.getNotificationRecipient().then((response) => {
      this.recipients = response;
    }).catch();
  }

  removeRecipient(notificationRecipient) {
    this.notificationRecipient.deleteNotificationRecipient(notificationRecipient.id).then((deleted) => {
      if (deleted) {
        this.snackbar.message = `${notificationRecipient.name} was removed from the list`;
        this.snackbar.openSnackBar();
        this.getRecipients();
      }
    }).catch();
  }

  addNewRecipientComponent() {
    const componentReference: ComponentRef<any> = this.recipientComponent.last.createComponent(this.recipientFactory);
    this.createNewRecipient = true;
    this.removeRecipientSubscription = componentReference.instance.removeRecipientEvent.subscribe((removedRecipient) => {
      if (removedRecipient.id) {
        this.removeRecipient(removedRecipient);
      }
      this.createNewRecipient = false;
      componentReference.destroy();
    });
    this.newRecipientSavedSubscription = componentReference.instance.newRecipientSavedEvent.subscribe((recipientCreated) => {
      this.createNewRecipient = !recipientCreated;
    });
  }

  ngOnDestroy() {
    if (this.removeRecipientSubscription) {
      this.removeRecipientSubscription.unsubscribe();
    }
    if (this.newRecipientSavedSubscription) {
      this.newRecipientSavedSubscription.unsubscribe();
    }
  }
}
