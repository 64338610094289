import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
	<footer>
		<div class="container">
			<div class="row">
				<div class="column logo-column">
					<img class="logo" src="assets/yearsy-logo.svg">
					<!--<p class="text-small">Lorem ipsum is simply a dummy text.</p>-->
				</div>
				<div class="column">
					<ul class="pure-menu-list">
						<!--<h4>Yearsy</h4>
						<li>Solutions</li>
						<li>Why Yearsy</li>
						<li>Resources</li>-->
					</ul>
				</div>
				<div class="column">
					<ul class="pure-menu-list">
						<!--<h4>Features</h4>
						<li>Cases Studies</li>
						<li>Testimonials</li>
						<li>Reviews</li>-->
					</ul>
				</div>
				<div class="column">
					<ul class="pure-menu-list">
						<!--<h4>Social</h4>
						<li>Facebook</li>
						<li>Instagram</li>
						<li>Twitter</li>-->
					</ul>
				</div>
				<div class="column">
					<ul class="pure-menu-list">
						<!--<h4>Contact us</h4>
						<li>contact@yearsy.com</li>
						<li>Östermalmsgatan</li>
						<li>037-616-5222</li>-->
					</ul>
				</div>
			</div>
		</div>
		<div class="bottom-bar">
		<div class="bottom-banner">
			<div class="column bottom-banner-cell-start">
				<p> © Copyright 2019 Yearsy</p>
			</div>
			<div class="column bottom-banner-cell-end d-flex">
				<!--<a>Privacy Policy</a>
				<a>Terms of Use</a>-->
			</div>
		</div>
		</div>
	</footer>
  `,
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  constructor() {}

}
