import { Component } from '@angular/core';

@Component({
  selector: 'app-layout',
  template: `
    <app-top-bar></app-top-bar>
    <router-outlet name="view"></router-outlet>
    <app-footer></app-footer>
  `,
  styles: [`
  :host {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    min-height: 100vh;
    width: 100%;
  }
  `]
})
export class LayoutComponent {
  constructor() {
    if (window.location.hash && window.location.hash === '#_=_') {
      window.location.hash = '';
    }
  }
}
