import { Component } from '@angular/core';
import { IMember, MemberService } from '../../services/member.service';

@Component({
  selector: 'app-upcoming-events',
  template: `
    <h2>Your events</h2>
    <div class="hr"></div>
    <div class="box-shadow-wrapper">
      <div *ngIf="(upcomingBirthdays?.length === 0 && todaysBirthdays?.length === 0) && (upcomingAnniversaries?.length === 0 && todaysWorkaversaries?.length === 0)">
        <p>You have no upcoming events. </p>
        <p>Try adding new members to your team.</p>
        <button class="primary-btn" type="button"><a [routerLink]="['/team']">add team members</a></button>
      </div>
      <div class="row" *ngIf="(upcomingBirthdays?.length > 0 || todaysBirthdays?.length > 0) || (upcomingAnniversaries?.length > 0 || todaysWorkaversaries?.length > 0)">
        <div class="upcoming-events">
          <h4>Birthdays</h4>
          <app-upcoming-event-card class="blue-header" *ngFor="let teamMember of todaysBirthdays" [teamMember]="teamMember" [anniversaryType]="'birthday'"></app-upcoming-event-card>
          <app-upcoming-event-card class="blue-header" *ngFor="let teamMember of upcomingBirthdays | slice: 0:birthdaysSlice" [teamMember]="teamMember" [anniversaryType]="'birthday'">
          </app-upcoming-event-card>
          <button class="primary-btn" *ngIf="showMoreBirthdayEvents" (click)="showMoreEvents('birthdays')">
            more birthdays
          </button>
        </div>
        <div class="upcoming-events">
          <h4>Workiversary</h4>
          <app-upcoming-event-card class="purple-header" *ngFor="let teamMember of todaysWorkaversaries" [teamMember]="teamMember" [anniversaryType]="'workdayAnniversary'"></app-upcoming-event-card>
          <app-upcoming-event-card class="purple-header" *ngFor="let teamMember of upcomingAnniversaries | slice: 0:workDaySlice" [teamMember]="teamMember" [anniversaryType]="'workdayAnniversary'">
          </app-upcoming-event-card>
          <button class="primary-btn" *ngIf="showMoreWorkDayEvents" (click)="showMoreEvents('workDays')">
            more workiversaries
          </button>
        </div>
        <div class="upcoming-events" *ngIf="upcomingProbations?.length > 0 || todaysProbations?.length > 0">
          <h4>Probation periods</h4>
          <app-upcoming-event-card class="green-header" *ngFor="let teamMember of todaysProbations" [teamMember]="teamMember" [anniversaryType]="'customAnniversary'"></app-upcoming-event-card>
          <app-upcoming-event-card class="green-header" *ngFor="let teamMember of upcomingProbations | slice: 0:probationSlice" [teamMember]="teamMember" [anniversaryType]="'customAnniversary'">
          </app-upcoming-event-card>
          <button class="primary-btn" *ngIf="showMoreProbationPeriods" (click)="showMoreEvents('probations')">
            Load more probations
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .primary-btn img {
      width: 1rem;
      height: 1rem;
      padding: 0 .5rem;
      align-self: center;
    }

    .primary-btn a {
      color: black;
    }

    .upcoming-events {
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      .upcoming-events {
        width: 45%;
        padding: .5rem 1rem;
      }
    }
    @media screen and (min-width: 1024px) {
      .upcoming-events {
        flex: 1;
      }
    }
    }
  `]
})
export class UpcomingEventsComponent {
  public anniversaryType: string;
  public birthdaysSlice: number;
  public workDaySlice: number;
  public probationSlice: number;
  public showMoreBirthdayEvents: boolean;
  public showMoreWorkDayEvents: boolean;
  public showMoreProbationPeriods: boolean;

  public upcomingBirthdays: Array<IMember>;
  public upcomingAnniversaries: Array<IMember>;
  public upcomingProbations: Array<any>;
  public todaysBirthdays: Array<IMember>;
  public todaysWorkaversaries: Array<IMember>;
  public todaysProbations: Array<IMember>;

  constructor(private events: MemberService) {
    this.birthdaysSlice = 3;
    this.workDaySlice = 3;
    this.probationSlice = 3;

    this.getTodaysEvents();
    this.getUpcomingBirthdays();
    this.getUpcomingWorkaversaries();
    this.getUpcomingProbationPeriods();
  }

  getTodaysEvents() {
    this.events.getDailyEvents().then((todaysEvents) => {
      todaysEvents.filter((todaysEvent) => {
        this.todaysBirthdays = todaysEvent.birthdays;
        this.todaysWorkaversaries = todaysEvent.workdayAnniversaries;
        this.todaysProbations = todaysEvent.customAnniversary;
      });
    }).catch();
  }

  getUpcomingBirthdays() {
    this.events.getTopUpcomingBirthdays().then((allUpcomingBirthdays: Array<any>) => {
      this.upcomingBirthdays = allUpcomingBirthdays;
      this.showMoreBirthdayEvents = this.upcomingBirthdays.length > 3;
    }).catch();
  }

  getUpcomingWorkaversaries() {
    this.events.getTopUpcomingAnniversaries().then((allUpcomingWorkaversaries: Array<any>) => {
      this.upcomingAnniversaries = allUpcomingWorkaversaries;
      this.showMoreWorkDayEvents = this.upcomingAnniversaries.length > 3;
    }).catch();
  }

  getUpcomingProbationPeriods() {
    this.events.getTopUpcomingProbations().then((memberResponse: Array<any>) => {
      this.upcomingProbations = memberResponse;
      this.showMoreProbationPeriods = this.upcomingProbations.length > 3;
    }).catch();
  }

  showMoreEvents(type: string) {
    if (type === 'birthdays') {
      this.showMoreBirthdayEvents = (this.birthdaysSlice + this.birthdaysSlice) >= this.upcomingBirthdays.length ? false : true;
      this.birthdaysSlice += this.birthdaysSlice;
    }
    if (type === 'workDays') {
      this.showMoreWorkDayEvents = (this.workDaySlice + this.workDaySlice) >= this.upcomingAnniversaries.length ? false : true;
      this.workDaySlice += this.workDaySlice;
    }
    if (type === 'probations') {
      this.showMoreProbationPeriods = (this.probationSlice + this.probationSlice) >= this.upcomingProbations.length ? false : true;
      this.probationSlice += this.probationSlice;
    }
  }
}
