import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CONFIG } from '../app.config';
import { interceptorSkipHeader } from './app.response.interceptor';
import { AuthService } from './auth.service';

export interface IUser {
  _id: string;
  name: string;
  roles: { member: boolean; admin: boolean; superadmin: boolean };
  email: string;
  token: string;
  facebook?: { id: string; token: string; messenger?: { user_ref: string; optIn: boolean } };
  hash?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userDataPromise: Promise<IUser>;
  private userData: IUser;
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.headers = new HttpHeaders().set(interceptorSkipHeader, '');
    this.authService.loggedOutEvent.subscribe((event) => {
      if (event) {
        this.resetUserData();
      }
    });
  }

  getUserData(): Promise<IUser> {
    if (!this.userData) {
      if (!this.userDataPromise) {
        this.userDataPromise = this.http.get(`${CONFIG.ENDPOINTS.USERS}/me`).toPromise().then((response: { data: any }) => {
          this.userData = response.data;
          return this.userData;
        }).catch(errorResponse => {
          throw new Error(errorResponse);
        });
      }
      return this.userDataPromise;
    }

    return Promise.resolve(this.userData);
  }

  hasRole(role: string): Promise<boolean> {
    return this.getUserData().then((userData: IUser) => {
      return userData.roles[role];
    }).catch(() => {
      return null;
    });
  }

  isAdmin(): Promise<boolean> {
    return this.hasRole('admin');
  }

  isSuperUser(): Promise<boolean> {
    return this.hasRole('superuser');
  }

  getEmail(): Promise<string> {
    return this.getUserData().then((userData: IUser) => {
      return userData.email;
    });
  }

  updateUserData(userId: string, userDataToChange: IUser): Promise<{ successful: boolean }> {
    return this.http.put(`${CONFIG.ENDPOINTS.USERS}/${userId}`, userDataToChange).toPromise().then((response: { updated: boolean }) => {
      if (response.updated) {
        this.resetUserData();
        return { successful: true };
      }
    }).catch(() => {
      return { successful: false };
    });
  }

  updateMyUserData(userDataToChange: any): Promise<{ successful: boolean }> {
    return this.http.put(`${CONFIG.ENDPOINTS.USERS}/me`, userDataToChange).toPromise().then((response: { updated: boolean }) => {
      if (response.updated) {
        this.resetUserData();
        return { successful: true };
      }
    }).catch(() => {
      return { successful: false };
    });
  }

  resetUserData() {
    this.userData = null;
    this.userDataPromise = null;
  }

  localLogin(email: string, password: string): Promise<{ status: boolean; message?: string }> {
    const headers = this.headers;

    return this.http.post(CONFIG.ENDPOINTS.LOCAL_LOGIN, { username: email, password: password }, { headers }).toPromise().then((response: { token: string }) => {
      if (response.token) {
        return { status: true };
      }

      return { status: true };
    }).catch((error) => {
      return { status: false, message: error.error.message };
    });
  }

  signUp(name: string, email: string, password: string): Promise<boolean> {
    const headers = this.headers;

    return this.http.post(CONFIG.ENDPOINTS.LOCAL_SIGN_UP, { name: name, username: email, password: password }, { headers }).toPromise().then((response: { token: string }) => {
      if (response.token) {
        return true;
      }

      return false;
    }).catch(() => {
      return false;
    });
  }

  matchPassword(form: FormGroup) {
    const password = form.controls.password.value;
    const confirmPassword = form.controls.confirmPassword.value;

    // Since it's only a validation and nothing that can be "hacked"
    // tslint:disable-next-line:possible-timing-attack
    if (password !== confirmPassword) {
      return { doesNotMatch: true };
    }

    return null;
  }

  samePassword(form: FormGroup) {
    const currentPassword = form.controls.currentPassword.value;
    const password = form.controls.password.value;

    // Since it's only a validation and nothing that can be "hacked"
    // tslint:disable-next-line:possible-timing-attack
    if (currentPassword === password) {
      return { samePassword: true };
    }

    return null;
  }

  checkCurrentPassword(currentPassword: string, localLogin: boolean): Promise<boolean> {
    if (localLogin) {
      return this.http.get(`${CONFIG.ENDPOINTS.USERS}/me/password/${currentPassword}`).toPromise().then((response: { exists: boolean }) => {
        if (response.exists) {
          return true;
        }

        return false;
      }).catch(() => {
        return false;
      });
    } else {
      return Promise.resolve(true);
    }
  }

  updatePassword(newPassword: string, currentPassword: string): Promise<boolean> {
    return this.http.put(`${CONFIG.ENDPOINTS.USERS}/me/update-password`, { newPassword: newPassword, currentPassword: currentPassword }).toPromise().then((response: { updated: boolean }) => {
      if (response.updated) {
        return true;
      }

      return false;
    }).catch(() => {
      return false;
    });
  }

  hasPassword(): Promise<boolean> {
    return this.http.get(`${CONFIG.ENDPOINTS.USERS}/me/password-exists`).toPromise().then((response: { exists: boolean }) => {
      if (response.exists) {
        return true;
      }

      return false;
    }).catch(() => {
      return false;
    });
  }

  getUserDataObjToUpdate(name: string, email: string, roles?: any) {
    const userDataObject = {
      name: {
        value: name,
        type: 'text'
      },
      email: {
        value: email,
        type: 'text'
      }
    };
    if (roles) {
      userDataObject['roles'] = {
        value: roles,
        type: 'checkbox',
        choices: CONFIG.USER_ROLES
      };
    }

    return userDataObject;
  }

  public getUserRef(): Promise<string | boolean> {
    return this.http.get(`${CONFIG.ENDPOINTS.USERS}/me/user-ref`).toPromise().then((response: { user_ref: string | boolean }) => {
      if (response.user_ref) {
        return response.user_ref;
      }

      return false;
    }).catch(() => {
      return false;
    });
  }

}
