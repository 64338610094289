import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NotificationRecipientService } from '../../services/notification-recipient.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dashboard',
  template: `
  <div [@fadeIn]="'in'" class="page-wrapper">
    <app-upcoming-events-graph></app-upcoming-events-graph>
    <app-upcoming-events></app-upcoming-events>
  </div>
  `,
  styles: [`
  `],
  animations: [
    trigger('fadeIn', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(900)
      ])
    ])
  ]
})
export class DashboardComponent implements OnInit {
  public teamMembersTitle: string;
  public numberOfMembers: number;
  public cardContent: any;
  public teamMembers: Array<any>;
  public averageYearsTitle: string;
  public averageYears: number;

  constructor(private user: UserService, private notificationRecipient: NotificationRecipientService) {
  }

  ngOnInit() {
    this.getNotificationRecipient();
  }

  getNotificationRecipient() {
    this.notificationRecipient.getNotificationRecipient().then((response) => {
      if (response.length === 0) {
        this.user.getUserData().then((user) => {
          const { name, email, _id } = user;
          const recipient = [{
            name: name,
            email: email,
            userId: _id
          }];
          this.notificationRecipient.setNotificationRecipient(recipient[0]).then().catch();
        }).catch();
      }
    }).catch();
  }
}
