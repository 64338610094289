import { Injectable } from '@angular/core';

declare var FB: any;
declare var window: any;
@Injectable()
export class FacebookSDKService {
  private reference: any;
  private referencePromise: Promise<any>;
  private appId: string;
  private pageId: string;

  constructor() {
    this.appId = '730046757349476';
    this.pageId = '111102833646079';
  }

  get(reparseDOM = true): Promise<any> {
    if (!this.reference) {
      if (!this.referencePromise) {
        this.referencePromise = new Promise((resolve) => {
          if (!FB) {
            window.fbAsyncInit = () => {
              this.init();
              this.reference = FB;
              resolve(this.reference);
            };
          } else {
            this.init();
            this.reference = FB;
            resolve(this.reference);
          }
        });
      }
      return this.referencePromise;
    }

    this.reparseDOM(reparseDOM);
    return Promise.resolve(this.reference);
  }

  init(): any {
    FB.init({
      appId: this.appId,
      xfbml: true,
      version: 'v18.0'
    });
  }

  getPageId(): string {
    return this.pageId;
  }

  getAppId(): string {
    return this.appId;
  }

  reparseDOM(nextTick = false): void {
    if (this.reference) {
      if (nextTick) {
        window.setTimeout(() => {
          FB.XFBML.parse();
        }, 1);
      } else {
        FB.XFBML.parse();
      }
    } else {
      const error = new Error(('Please call init() before using service.'));
      error.name = 'SDKNotInitialized';
      throw error;
    }
  }

  trackEvent(eventName, params) {
    FB.AppEvents.logEvent(eventName, null, {
      app_id: this.getAppId(),
      page_id: this.getPageId(),
      ...params
    });
  }
}
