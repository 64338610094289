import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-card',
  template: `
		<div class="card">
			<div class="card-header">
				<h4>
					{{cardHeader}}
				</h4>
			</div>
			<div class="card-content">
				<p>
					{{cardContent}}
					<span *ngIf="cardInfo !== null" class="card-info">{{cardInfo}}</span>
				</p>
			</div>
    </div>
  `,
	styles: [`
		.card {
			box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
			-moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
			-webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
			border-radius: 5px;
			text-align: center;
			height: 100%;
			width: 14rem;
			margin: 1rem auto;
      background-color: white;
    }

    .card-header {
      color: white;
    }

	.card-info {
		font-size: 0.65rem;
		display: block;
		margin: auto;
		width: 90%;
	}

    :host.blue-header .card-header {
      background-color: var(--yearsyDarkBlue);
    }

    :host.green-header .card-header {
      background-color: var(--yearsyLightBlue);
    }

    :host.purple-header .card-header {
      background-color: var(--yearsyLightPink);
    }

    :host.yellow-header .card-header {
      background-color: var(--yearsyYellow);
    }

		.card-header, .card-content {
			padding: .1rem;
			height: 50%;
		}

		@media only screen and (min-width: 768px) {
			.card {
				margin: 0 1rem;
			}
		}

  `]
})
export class InfoCardComponent {
	@Input() cardHeader: string;
	@Input() cardContent: any;
	@Input() cardInfo: any;

  constructor() { }

}
