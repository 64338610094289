import { Component, ElementRef, ViewChild } from '@angular/core';
import { ConfirmService } from '../../services/confirm.service';

const ESCAPE_KEY = 27;

@Component({
  selector: 'app-confirmation-modal',
  template: `
  <div [ngClass]="{'modal-visible': modalVisible}" class="modal-wrapper">
    <div class="modal" id="modal">
      <div class="modal-label">{{title}}</div>
      <div class="content">
        <p *ngIf="prompt">{{prompt}}</p>
        <div class="secondary-prompt-wrapper" *ngIf="secondaryPrompt">
          <p class="secondary-prompt" *ngFor="let prompt of secondaryPrompt">{{prompt}}</p>
        </div>
        <form #editData="ngForm" class="pure-form pure-form-aligned">
          <fieldset *ngIf="dataToChange">
              <div *ngFor="let data of dataToChange | ObjectKeys" class="pure-control-group">
                <input *ngIf="dataToChange[data].type === 'text'" name="{{data}}" type="text" [attr.type]="data === 'email' ? 'email' : 'text'" placeholder="{{ data | titlecase }}"
                [(ngModel)]="dataToChange[data].value" required minlength="3" [email]="data === 'email'">
                <ng-container *ngIf="dataToChange[data].type === 'checkbox'">
                  <label *ngFor="let box of dataToChange[data].choices; let i = index" class="checkbox-label">
                    <input name="{{dataToChange[data].choices[i]}}" type="checkbox" [(ngModel)]="dataToChange[data].value[dataToChange[data].choices[i]]">{{dataToChange[data].choices[i]}}</label>
                </ng-container>
              </div>
          </fieldset>
          <div class="modal-buttons">
            <button #confirmButton class="pure-button confirm" [ngClass]="{'decline': !dataToChange}" [disabled]="!editData.valid">{{ confirmButtonLabel }}</button>
            <button #declineButton class="pure-button cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  `,
  styles: [`
  .modal-wrapper {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: #4a4a4a;
    background: rgba(0,0,0,0.3);
    font-weight: 300;
    font-size: 0.7em;
  }
  .modal {
    width: 80%;
    max-width: 40rem;
    height: auto;
    text-align: center;
    vertical-align: middle;
    background: #ffffff;
  }
  .modal-visible {
    display: flex;
  }
  .modal-label {
    padding: 1.4rem 0 1.1rem;
    border: solid #eee;
    border-width: 0 0 0.0625rem;
    font-weight: 300;
    letter-spacing: 0.04375em;
  }
  .content {
    margin: 1.5rem auto 2rem;
  }
  .content input {
    width: 80%;
    max-width: 20rem;
    margin: auto;
  }
  .content p {
    margin: 0 1rem 2rem;
  }
  .modal-buttons {
    margin: 0.5rem 0 0 0;
  }
  .pure-button {
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.25rem;
    padding: 0.7rem 2rem;
    font-size: 0.9rem;
    display: inline-block;
    color: #ffffff;
    background: #9b9b9b;
    box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.1);
  }
  .confirm {
    background: var(--yearsyDarkBlue);
  }
  .decline {
    background: #da544d;
  }
  .cancel {
    margin-left: 0.2rem;
  }
  `]
})
export class ConfirmationModalComponent {
  public title: string;
  public prompt: string;
  public secondaryPrompt: string;
  public dataToChange: any;
  public modalVisible: boolean;
  public confirmButtonLabel: string;

  @ViewChild('confirmButton', { static: true }) confirmButtonElement: ElementRef;
  @ViewChild('declineButton', { static: true }) declineButtonElement: ElementRef;

  constructor(confirmService: ConfirmService) {
    confirmService.activate = this.activate.bind(this);
    this.modalVisible = false;
  }

  activate(title: string, prompt: string, dataToChange?: any, confirmButtonText?: string, secondaryPrompt?: string) {
    this.title = title;
    this.prompt = prompt;
    this.dataToChange = dataToChange;
    this.secondaryPrompt = secondaryPrompt;

    if (confirmButtonText) {
      this.confirmButtonLabel = confirmButtonText;
    } else if (dataToChange && secondaryPrompt) {
      this.confirmButtonLabel = 'Create custom audience';
    } else if (dataToChange) {
      this.confirmButtonLabel = 'Save changes';
    } else {
      this.confirmButtonLabel = 'Yes! I\'m sure';
    }

    return new Promise<boolean>(resolve => {
        this.showModal(resolve);
    });
  }

  showModal(resolve: (boolean) => any) {
    this.modalVisible = true;

    this.declineButtonElement.nativeElement.onclick = (() => {
      this.modalVisible = false;
      resolve({ confirmed: false });
    });

    this.confirmButtonElement.nativeElement.onclick = (() => {
      this.modalVisible = false;
      resolve({ confirmed: true, changedData: this.dataToChange });
    });

    document.onkeyup = (event: KeyboardEvent) => {
      if (event.which === ESCAPE_KEY) {
        this.modalVisible = false;
        return resolve({ confirmed: false });
      }
    };
  }
}
