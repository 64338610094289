import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-notification',
  template: `
  <div [@fadeIn]="'in'" class="page-wrapper">
    <app-recipient></app-recipient>
  </div>
  `,
  styles: [`
  `],
  animations: [
    trigger('fadeIn', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(900)
      ])
    ])
  ]
})
export class NotificationsComponent {
  constructor() {}
}
