import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dateFns from 'date-fns';
import { CONFIG } from '../app.config';

export interface IMember {
  _id: string;
  name: string;
  team: string;
  department: Array<any>;
  email: string;
  gender: string;
  location: string;
  birthday: Date;
  workdayAnniversary: Date;
  customAnniversary?: Date;
  employmentEndDate?: Date;
  probationPeriod?: Number;
}

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  public thisYearsDate: Date = dateFns.startOfToday();
  constructor(private http: HttpClient) { }

  addMember(member: IMember): Promise<boolean> {
    return this.http.post(CONFIG.ENDPOINTS.MEMBERS, member).toPromise().then((response: Response) => {
      if (response.status === 200) {
        return true;
      }

      return false;
    }).catch();
  }

  updateMember(member: IMember): Promise<boolean> {
    const memberId = member._id;
    return this.http.put(`${CONFIG.ENDPOINTS.MEMBERS}/update/${memberId}`, member)
    .toPromise().then((response: { updated: boolean }) => {
      if (response.updated) {
        return true;
      }

      return false;
    }).catch();
  }

  deleteMember(memberId: string): Promise<boolean> {
    return this.http.delete(`${CONFIG.ENDPOINTS.MEMBERS}/${memberId}`).toPromise().then((response: { deleted: boolean }) => {
      if (response.deleted) {
        return true;
      }

      return false;
    }).catch();
  }

  getMembers(showInactiveMemberData: boolean): Promise<Array<any>> {
    return this.http.get(CONFIG.ENDPOINTS.MEMBERS).toPromise().then((memberResponse: { data: Array<any> }) => {
      if (memberResponse.data) {
        if (showInactiveMemberData) {
          return memberResponse.data.map((teamMember) => {
            return teamMember;
          });
        } else {
          const activeMembers = memberResponse.data.map((teamMember) => {
            return (teamMember.employmentEndDate === null || teamMember.employmentEndDate === undefined) ? teamMember : null;
          });
          return activeMembers.filter(member => {
            return member !== null;
          });
        }
      } else {
        return [];
      }
    }).catch();
  }

  checkIfSameDate(date: Date): boolean {
    const { dayOfAnniversaryYear, dayOfThisYear } = this.getDaysOfYear(date);
    return dayOfAnniversaryYear === dayOfThisYear;
  }

  calculateYears(date: Date): number {
    let calendarYears = 0;
    if (date) {
      calendarYears = dateFns.differenceInCalendarISOYears(this.thisYearsDate, date);
      const { dayOfAnniversaryYear, dayOfThisYear } = this.getDaysOfYear(date);

      if (dayOfAnniversaryYear < dayOfThisYear) {
        return calendarYears + 1;
      }
    }
    return calendarYears;
  }

  getDifferenceInMonths(date) {
    let numberOfMOnths = 0;
    if (date) {
      numberOfMOnths = dateFns.differenceInMonths(this.thisYearsDate, date);
    }
    return numberOfMOnths;
  }

  getDifferenceInDays(date: Date) {
    const { dayOfAnniversaryYear, dayOfThisYear } = this.getDaysOfYear(date);
    return dayOfAnniversaryYear - dayOfThisYear > 0 ? dayOfAnniversaryYear - dayOfThisYear : (dayOfAnniversaryYear - dayOfThisYear) + dateFns.getDaysInYear(this.thisYearsDate);
  }

  getDaysOfYear(date: Date): { dayOfAnniversaryYear: number; dayOfThisYear: number } {
    let dayOfAnniversaryYear = dateFns.getDayOfYear(date);
    let dayOfThisYear = dateFns.getDayOfYear(this.thisYearsDate);
    if (dateFns.isLeapYear(date) && dayOfThisYear > 59) {
      dayOfAnniversaryYear = dateFns.getDayOfYear(date) - 1;
    }

    if (dateFns.isLeapYear(this.thisYearsDate) && dayOfThisYear > 59) {
      dayOfThisYear = dateFns.getDayOfYear(this.thisYearsDate) - 1;
    }

    return { dayOfAnniversaryYear: dayOfAnniversaryYear, dayOfThisYear: dayOfThisYear };
  }

  getEvents(): Promise<Array<IMember>> {
    return this.http.get(`${CONFIG.ENDPOINTS.MEMBERS}`).toPromise().then((events: { data: any }) => {
      return events.data.filter(event => {
        if (event.employmentEndDate === null || event.employmentEndDate === undefined) {
          return event;
        }
      });
    }).catch();
  }

  getDailyEvents(): Promise<Array<{ birthdays: Array<IMember>; workdayAnniversaries: Array<IMember>; customAnniversary: Array<IMember> }>> {
    return this.getEvents().then((allEvents) => {
      const birthdays = [];
      const workdayAnniversaries = [];
      const probations = [];
      allEvents.filter((event) => {
        if (this.checkIfSameDate(event.birthday)) {
          birthdays.push(event);
        }
        if (this.checkIfSameDate(event.workdayAnniversary)) {
          workdayAnniversaries.push(event);
        }
        if (this.checkIfSameDate(event.customAnniversary)) {
          probations.push(event);
        }
      });

      return Promise.resolve([{ birthdays: birthdays, workdayAnniversaries: workdayAnniversaries, customAnniversary: probations }]);
    }).catch();
  }

  getTopUpcomingBirthdays(todaysDate?: boolean): Promise<Array<IMember>> {
    const birthdayEvents = this.getEvents().then((topEvents) => {
      return topEvents.filter((event) => {
        if (todaysDate) {
          return event.birthday;
        } else {
          return event.birthday && !this.checkIfSameDate(event.birthday);
        }
      }).sort((a, b) => {
        const firstComparisonDifferenceInDays = this.getDifferenceInDays(a.birthday);
        const secondComparisonDifferenceInDays = this.getDifferenceInDays(b.birthday);
        return firstComparisonDifferenceInDays - secondComparisonDifferenceInDays;
      });
    }).catch();

    return Promise.resolve(birthdayEvents);
  }

  getTopUpcomingAnniversaries(todaysDate?: boolean): Promise<Array<IMember>> {
    const anniversaryEvents = this.getEvents().then((topEvents) => {
      return topEvents.filter((event) => {
        if (todaysDate) {
          return event.workdayAnniversary;
        } else {
          return event.workdayAnniversary && !this.checkIfSameDate(event.workdayAnniversary);
        }
      }).sort((a, b) => {
        const firstComparisonDifferenceInDays = this.getDifferenceInDays(a.workdayAnniversary);
        const secondComparisonDifferenceInDays = this.getDifferenceInDays(b.workdayAnniversary);
        return firstComparisonDifferenceInDays - secondComparisonDifferenceInDays;
      });
    }).catch();

    return Promise.resolve(anniversaryEvents);
  }

  getTopUpcomingProbations(): Promise<Array<IMember>> {
    const probationPeriods = this.getEvents().then((topEvents) => {
      return topEvents.filter((event) => {
        return event.customAnniversary && !this.checkIfSameDate(event.customAnniversary);
      }).sort((a, b) => {
        const firstComparisonDifferenceInDays = this.getDifferenceInDays(a.customAnniversary);
        const secondComparisonDifferenceInDays = this.getDifferenceInDays(b.customAnniversary);
        return firstComparisonDifferenceInDays - secondComparisonDifferenceInDays;
      });
    }).catch();

    return Promise.resolve(probationPeriods);
  }

  generateUpcomingAnniversaryText(teamMember: IMember, type: string): string {
    const differenceInYears = this.calculateYears(teamMember[type]);

    if (type === 'birthday') {
      return `is turning ${differenceInYears} years old`;
    }

    if (type === 'workdayAnniversary') {
      return `${differenceInYears} ${differenceInYears > 1 ? 'years' : 'year'} @ your company`;
    }

    if (type === 'customAnniversary') {
      return `Probation period ends`;
    }

    if (type === 'employmentEndDate') {
      return `{differenceInYears} years ago`;
    }

    return '';
  }
}
