import { Component } from '@angular/core';
import { NotificationSettingsService } from '../../services/notification-settings.service';

@Component({
  selector: 'app-notification-settings',
  template: `
    <h2>Notification settings</h2>
    <div class="hr"></div>
    <div class="box-shadow-wrapper row">
      <div class="notification-wrapper d-flex">
      <h3>Birthdays</h3>
        <div class="check-boxes-wrapper d-flex">
          <div class="check-box">
            <img *ngIf="!birthdayCheckedSameDayBox" src="../../../assets/unchecked.svg" alt="Same day" (click)="setValues(birthdayCheckedSameDayBox = !birthdayCheckedSameDayBox, 'birthday', 0)">
            <img *ngIf="birthdayCheckedSameDayBox" src="../../../assets/checked.svg" alt="Same day" (click)="setValues(birthdayCheckedSameDayBox = !birthdayCheckedSameDayBox, 'birthday', 0)">
            <p>Same day</p>
          </div>
          <div class="check-box">
            <img *ngIf="!birthdayCheckedDayBeforeBox" src="../../../assets/unchecked.svg" alt="Day before"
            (click)="setValues(birthdayCheckedDayBeforeBox = !birthdayCheckedDayBeforeBox, 'birthday', 86400000)">
            <img *ngIf="birthdayCheckedDayBeforeBox" src="../../../assets/checked.svg" alt="Day before"
            (click)="setValues(birthdayCheckedDayBeforeBox = !birthdayCheckedDayBeforeBox, 'birthday', 86400000)">
            <p>Day before</p>
          </div>
          <div class="check-box">
            <img *ngIf="!birthdayCheckedWeekBeforeBox" src="../../../assets/unchecked.svg" alt="Week before"
            (click)="setValues(birthdayCheckedWeekBeforeBox = !birthdayCheckedWeekBeforeBox, 'birthday', 604800000)">
            <img *ngIf="birthdayCheckedWeekBeforeBox" src="../../../assets/checked.svg" alt="Week before"
            (click)="setValues(birthdayCheckedWeekBeforeBox = !birthdayCheckedWeekBeforeBox, 'birthday', 604800000)">
            <p>Week before</p>
          </div>
        </div>
      </div>
      <div class="notification-wrapper d-flex">
        <h3>Workiversary</h3>
        <div class="check-boxes-wrapper d-flex">
          <div class="check-box">
            <img *ngIf="!workDayCheckedSameDayBox" src="../../../assets/unchecked.svg" alt="Same day" (click)="setValues(workDayCheckedSameDayBox = !workDayCheckedSameDayBox, 'workDay', 0)">
            <img *ngIf="workDayCheckedSameDayBox" src="../../../assets/checked.svg" alt="Same day" (click)="setValues(workDayCheckedSameDayBox = !workDayCheckedSameDayBox, 'workDay', 0)">
            <p>Same day</p>
          </div>
          <div class="check-box">
            <img *ngIf="!workDayCheckedDayBeforeBox" src="../../../assets/unchecked.svg" alt="Day before"
            (click)="setValues(workDayCheckedDayBeforeBox = !workDayCheckedDayBeforeBox, 'workDay', 86400000)">
            <img *ngIf="workDayCheckedDayBeforeBox" src="../../../assets/checked.svg" alt="Day before"
            (click)="setValues(workDayCheckedDayBeforeBox = !workDayCheckedDayBeforeBox, 'workDay', 86400000)">
            <p>Day before</p>
          </div>
          <div class="check-box">
            <img *ngIf="!workDayCheckedWeekBeforeBox" src="../../../assets/unchecked.svg" alt="Week before"
            (click)="setValues(workDayCheckedWeekBeforeBox = !workDayCheckedWeekBeforeBox, 'workDay', 604800000)">
            <img *ngIf="workDayCheckedWeekBeforeBox" src="../../../assets/checked.svg" alt="Week before"
            (click)="setValues(workDayCheckedWeekBeforeBox = !workDayCheckedWeekBeforeBox, 'workDay', 604800000)">
            <p>Week before</p>
          </div>
        </div>
      </div>
      <div class="notification-wrapper d-flex">
        <h3>Probation Period</h3>
        <div class="check-boxes-wrapper d-flex">
          <div class="check-box">
            <img *ngIf="!customCheckedSameDayBox" src="../../../assets/unchecked.svg" alt="2 Weeks before"
             (click)="setValues(customCheckedSameDayBox = !customCheckedSameDayBox, 'custom', 1209600000)">
            <img *ngIf="customCheckedSameDayBox" src="../../../assets/checked.svg" alt="2 Weeks before" (click)="setValues(customCheckedSameDayBox = !customCheckedSameDayBox, 'custom', 1209600000)">
            <p>2 Weeks before</p>
          </div>
          <div class="check-box">
            <img *ngIf="!customCheckedDayBeforeBox" src="../../../assets/unchecked.svg" alt="Month before"
            (click)="setValues(customCheckedDayBeforeBox = !customCheckedDayBeforeBox, 'custom', 2419200000)">
            <img *ngIf="customCheckedDayBeforeBox" src="../../../assets/checked.svg" alt="Month before"
            (click)="setValues(customCheckedDayBeforeBox = !customCheckedDayBeforeBox, 'custom', 2419200000)">
            <p>Month before</p>
          </div>
          <div class="check-box">
            <img *ngIf="!customCheckedWeekBeforeBox" src="../../../assets/unchecked.svg" alt="2 Months before"
            (click)="setValues(customCheckedWeekBeforeBox = !customCheckedWeekBeforeBox, 'custom', 4838400000)">
            <img *ngIf="customCheckedWeekBeforeBox" src="../../../assets/checked.svg" alt="2 Months before"
            (click)="setValues(customCheckedWeekBeforeBox = !customCheckedWeekBeforeBox, 'custom', 4838400000)">
            <p>2 Months before</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
  h3 {
    margin-top: 0;
  }

  .notification-wrapper {
    padding: 1rem;
    flex-direction: column;
    margin: 0 auto 1rem 0;
  }

  .check-boxes-wrapper {
    flex-direction: column;
  }

  .check-box {
    display: flex;
    align-items: center;
    max-height: 1.5rem;
  }

  .check-box p {
    padding: 0 0 0 0.5rem;
  }
  `]
})
export class NotificationSettingsComponent {
  private birthdayNotificationValues: Array<number>;
  private workdayNotificationValues: Array<number>;
  private customNotificationValues: Array<number>;
  public birthdayCheckedSameDayBox: boolean;
  public birthdayCheckedDayBeforeBox: boolean;
  public birthdayCheckedWeekBeforeBox: boolean;
  public workDayCheckedSameDayBox: boolean;
  public workDayCheckedDayBeforeBox: boolean;
  public workDayCheckedWeekBeforeBox: boolean;
  public customCheckedSameDayBox: boolean;
  public customCheckedDayBeforeBox: boolean;
  public customCheckedWeekBeforeBox: boolean;

  public birthdayNotifications: Array<number>;
  public workdayNotifications: Array<number>;
  public customNotifications: Array<number>;
  public error: boolean;

  constructor(private notificationsSettings: NotificationSettingsService) {
    this.birthdayNotificationValues = [];
    this.workdayNotificationValues = [];
    this.customNotificationValues = [];

    this.notificationsSettings.getNotificationSettings().then((response) => {
      if (response.length > 0) {
        const notificationsTimes = response[0].notificationTimes;
        const { birthday, custom, workday } = notificationsTimes;
        if (birthday.length > 0) {
          this.birthdayNotificationValues = [...notificationsTimes['birthday']];
          this.birthdayNotificationValues.forEach((value) => {
            if (value === 0) {
              this.birthdayCheckedSameDayBox = true;
            }
            if (value === 86400000) {
              this.birthdayCheckedDayBeforeBox = true;
            }
            if (value === 604800000) {
              this.birthdayCheckedWeekBeforeBox = true;
            }
          });
        }
        if (workday.length > 0) {
          this.workdayNotificationValues = [...notificationsTimes['workday']];
          this.workdayNotificationValues.forEach((value) => {
            if (value === 0) {
              this.workDayCheckedSameDayBox = true;
            }
            if (value === 86400000) {
              this.workDayCheckedDayBeforeBox = true;
            }
            if (value === 604800000) {
              this.workDayCheckedWeekBeforeBox = true;
            }
          });
        }
        if (custom.length > 0) {
          this.customNotificationValues = [...notificationsTimes['custom']];
          this.customNotificationValues.forEach((value) => {
            if (value === 1209600000) {
              this.customCheckedSameDayBox = true;
            }
            if (value === 2419200000) {
              this.customCheckedDayBeforeBox = true;
            }
            if (value === 4838400000) {
              this.customCheckedWeekBeforeBox = true;
            }
          });
        }
      }
    }).catch();

    this.error = false;
  }

  setValues(isSet: boolean, type: string, value: number) {
    if (type === 'birthday') {
      if (isSet) {
        this.birthdayNotificationValues.push(value);
      } else {
        this.birthdayNotificationValues.splice(this.birthdayNotificationValues.indexOf(value), 1);
      }
    }
    if (type === 'workDay') {
      if (isSet) {
        this.workdayNotificationValues.push(value);
      } else {
        this.workdayNotificationValues.splice(this.workdayNotificationValues.indexOf(value), 1);
      }
    }
    if (type === 'custom') {
      if (isSet) {
        this.customNotificationValues.push(value);
      } else {
        this.customNotificationValues.splice(this.customNotificationValues.indexOf(value), 1);
      }
    }
    this.setNotificationsSettings();
  }

  setNotificationsSettings() {
    this.error = false;
    const notificationTimesObject = { birthday: [], workday: [], custom: [] };

    notificationTimesObject['birthday'] = [...this.birthdayNotificationValues].filter(value => Number.isInteger(value) && value >= 0);
    notificationTimesObject['workday'] = [...this.workdayNotificationValues].filter(value => Number.isInteger(value) && value >= 0);
    notificationTimesObject['custom'] = [...this.customNotificationValues].filter(value => Number.isInteger(value) && value >= 0);
    const notificationsSettings = { notificationTimes: notificationTimesObject };

    this.notificationsSettings.setNotificationSettings(notificationsSettings).then((response) => {
      if (response.error) {
        this.error = true;
      }
    }).catch();
  }
}
