import { Component, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';
import { MemberService } from '../../services/member.service';

@Component({
  selector: 'app-upcoming-events-graph',
  template: `
  <div class="graph" style="display: block">
    <canvas baseChart
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="chartOptions"
      [legend]="true"
      [chartType]="'bar'">
    </canvas>
  </div>
  `,
  styles: [`
    .graph {
      height: 60vh;
    }
  `]
})
export class UpcomingEventsGraphComponent implements OnInit {
  public timelineMonthsDefault;
  public barChartLabels: Array<any>;
  public barChartData: Array<{ data: Array<any>; members: Array<any>; label: string; backgroundColor: string; borderColor: string; hoverBackgroundColor: string}>;
  public tooltipCallbacks: any;
  public chartOptions: any;

  constructor(private member: MemberService) {
    this.barChartLabels = [];
    this.barChartData = [
      { data: [], members: [], label: '', backgroundColor: 'rgb(43, 88, 236, 0.6)', borderColor: 'rgb(43, 88, 236, 1)', hoverBackgroundColor: 'rgb(43, 88, 236, 0.8)' },
      { data: [], members: [], label: '', backgroundColor: 'rgb(180, 30, 243, 0.6)', borderColor: 'rgb(180, 30, 243, 1)', hoverBackgroundColor: 'rgb(180, 30, 243, 0.8)' }
    ];

    this.timelineMonthsDefault = {
      Jan: {
        value: 0,
        members: []
      },
      Feb: {
        value: 0,
        members: []
      },
      Mar: {
        value: 0,
        members: []
      },
      Apr: {
        value: 0,
        members: []
      },
      May: {
        value: 0,
        members: []
      },
      Jun: {
        value: 0,
        members: []
      },
      Jul: {
        value: 0,
        members: []
      },
      Aug: {
        value: 0,
        members: []
      },
      Sep: {
        value: 0,
        members: []
      },
      Oct: {
        value: 0,
        members: []
      },
      Nov: {
        value: 0,
        members: []
      },
      Dec: {
        value: 0,
        members: []
      }
    };
  }

  ngOnInit() {
    this.getAnniversaryMonths('workdayAnniversary');
    this.getAnniversaryMonths('birthday');
    this.setGraphCallbackOptions();

    Object.keys(this.timelineMonthsDefault).forEach(month => {
      this.barChartLabels.push(month);
    });
  }
  setGraphCallbackOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        displayColors: false,
        callbacks: {
          label: (tooltipItem, chartData) => {
            return chartData.datasets[tooltipItem.datasetIndex].members[tooltipItem.index];
          }, title: (tooltipItem, chartData) => {
            const label = tooltipItem[0].label;
            const celebration = chartData.datasets[tooltipItem[0].datasetIndex].label;
            return `${celebration} in ${label}: `;
          }
        }
      }
    };
  }

  setChartData(anniversaryMonths, anniversaryType: string) {
    let i;
    if (anniversaryType === 'workdayAnniversary') {
      i = 0;
      this.barChartData[0].label = 'Workiversaries';

    } else if (anniversaryType === 'birthday') {
      i = 1;
      this.barChartData[1].label = 'Birthdays';
    }
    this.barChartData[i].data.push(anniversaryMonths.value);
    this.barChartData[i].members.push(anniversaryMonths.members);
  }

  getAnniversaryMonths(anniversaryType: string) {
    const anniversariesPerMonth = {};
    this.member.getEvents().then((response: Array<any>) => {
      Object.keys(this.timelineMonthsDefault).forEach(month => {
        const anniversaryMonth = response.reduce((accumulator, teamMember) => {
          if (dateFns.format(new Date(teamMember[anniversaryType]), 'MMM') === month) {
            ++accumulator.value;
            accumulator.members.push(teamMember.name);
            return accumulator;
          } else {
            return accumulator;
          }
        }, {
          value: 0,
          members: []
        });
        anniversariesPerMonth[month] = anniversaryMonth;

        this.setChartData(anniversariesPerMonth[month], anniversaryType);
      });
    }).catch();
  }
}
