import { Component } from '@angular/core';

@Component({
  selector: 'app-admin',
  template: `
  <app-user-list></app-user-list>
  `,
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  constructor() { }

}
