import { Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UserSettingsComponent } from './components/settings/user-settings.component';
import { SignupComponent } from './components/signup/signup.component';
import { TeamListComponent } from './components/team/team-list.component';

import { UserIsAdmin } from './guards/user-is-admin.guard';
import { UserIsNotLoggedIn } from './guards/user-is-not-logged-in.guard';

export const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UserIsNotLoggedIn]
  },
  {
    path: 'sign-up',
    component: SignupComponent,
    canActivate: [UserIsNotLoggedIn]
  },
  {
    path: 'dashboard',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        outlet: 'view'
      }
    ]
  },
  {
    path: 'team',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: TeamListComponent,
        outlet: 'view'
      }
    ]
  },
  {
    path: 'notifications',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: NotificationsComponent,
        outlet: 'view'
      }
    ]
  },
  {
    path: 'settings',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: UserSettingsComponent,
        outlet: 'view'
      }
    ]
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivate: [UserIsAdmin],
    children: [
      {
        path: '',
        component: AdminComponent,
        outlet: 'view'
      }
    ]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];
