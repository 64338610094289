import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-settings',
  template: `
  <div [@fadeIn]="'in'" class="page-wrapper">
    <h2>Settings</h2>
    <div class="hr"></div>
    <div class="box-shadow-wrapper">
      <form class="d-flex" [formGroup]="passwordFormGroup" #passwordSettingsForm="ngForm" (ngSubmit)="updatePassword(passwordFormGroup)">
        <p *ngIf="user">Editing info for user: {{ user.email }}</p>
        <mat-form-field *ngIf="isLocalLogin">
          <mat-label>Current password</mat-label>
          <input type="password" matInput placeholder="Current Password" id="currentPassword" formControlName="currentPassword" autocomplete="current-password" aria-placeholder="Current password">
        </mat-form-field>
        <div class="error-message" *ngIf="(currentPasswordControler?.touched || currentPasswordControler?.dirty) && currentPasswordControler.invalid">This password does not exist.</div>
        <mat-form-field>
          <mat-label>New password</mat-label>
          <input type="password" matInput placeholder="New password" id="password" formControlName="password" autocomplete="new-password" aria-placeholder="New password">
        </mat-form-field>
        <div class="error-message" *ngIf="(passwordControler?.touched || passwordControler?.dirty) && passwordControler.invalid">
          Your password should be at least 8 characters, contain a capital letter and a number.</div>
        <mat-form-field>
          <mat-label>Confirm new password</mat-label>
          <input type="password" matInput placeholder="New password" id="confirmPassword" formControlName="confirmPassword" autocomplete="new-password" aria-placeholder="Confirm password">
        </mat-form-field>
        <div class="error-message" *ngIf="(confirmControler?.touched || confirmControler?.dirty) && passwordFormGroup.errors">
          The passwords don't match</div>
        <div class="icons-wrapper d-flex">
          <button class="primary-btn" type="submit">save</button>
        </div>
      </form>
    </div>

    <h2>API Token</h2>
    <div class="hr"></div>
    <div class="box-shadow-wrapper">
      <form class="d-flex" id="api-token-form" [formGroup]="apiFormGroup" #apiTokenForm="ngForm">
        <p>This is a unique identifier used to authenticate you to yearsy. Keep your Token private to avoid sharing sensitive information.</p>
        <mat-form-field #tokenField (click)="toggleShowToken()">
          <mat-label *ngIf="!tokenField._control.focused && !showToken">Click to reveal token</mat-label>
          <mat-label *ngIf="tokenField._control.focused && showToken">API Token (Click to copy)</mat-label>
          <input type="text" matInput placeholder="API Token (Click to copy)" id="api-token" formControlName="apiToken" aria-placeholder="(Click to copy)" value="apiToken" readonly="true">
        </mat-form-field>
        <div class="icons-wrapper d-flex">
          <button class="primary-btn" type="button" (click)="deleteToken()">Delete token</button>
        </div>
      </form>
    </div>
  </div>
  `,
  styles: [`
  form {
    margin: 0 auto;
    flex-direction: column;
    max-width: 20rem;
  }

  input {
    width: 20rem;
    outline: none;
  }

  form#api-token-form {
    max-width: 40rem;
  }

  form#api-token-form input {
    width: -webkit-fill-available;
    text-overflow: ellipsis;
  }

  .icons-wrapper {
    padding: 1rem 0;
  }
  `],
  animations: [
    trigger('fadeIn', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(900)
      ])
    ])
  ]
})
export class UserSettingsComponent implements OnInit {
  public user: any;
  public editName: boolean;
  public filledOutFakePassword: string;
  public passwordFormGroup: FormGroup;
  public apiFormGroup: FormGroup;
  public submitted: boolean;
  public isLocalLogin: boolean;
  public message: string;
  public apiToken: string;
  public showToken: boolean;

  @ViewChild('passwordSettingsForm', { static: true }) passwordSettingsForm;
  @ViewChild('apiTokenForm', { static: true }) apiTokenForm;

  constructor(private users: UserService, private form: FormBuilder, private snackBar: MatSnackBar, private auth: AuthService) {
    this.filledOutFakePassword = Array(15).fill('*').join('').toString();
    this.editName = false;
    this.submitted = false;
    this.apiToken = '';
    this.showToken = false;
    this.passwordFormGroup = this.form.group({
      currentPassword: ['', { disabled: !this.isLocalLogin }, Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$')
      ]],
      confirmPassword: ['', Validators.required]
    }, { validator: [users.matchPassword, users.samePassword] });
    this.apiFormGroup = this.form.group({
      apiToken: ['']
    });

  }

  ngOnInit() {
    this.users.getUserData().then((user) => {
      this.user = user;
    }).catch();

    this.users.hasPassword().then((hasPassword) => {
      this.isLocalLogin = hasPassword;
    }).catch();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 5000,
      panelClass: 'snackbar-style'
    });
  }

  get currentPasswordControler() {
    return this.passwordFormGroup.get('currentPassword');
  }

  get passwordControler() {
    return this.passwordFormGroup.get('password');
  }

  get confirmControler() {
    return this.passwordFormGroup.get('confirmPassword');
  }

  updateName(name: string) {
    this.users.updateMyUserData({ name: name }).then((updated) => {
      if (updated) {
        this.editName = false;
        this.message = 'The name has been updated.';
      } else {
        this.message = 'There was an unexpected error updating the name.';
      }
    }).catch();
  }

  updatePassword(passwordFormGroup: FormGroup) {
    this.submitted = true;
    const currentPassword = passwordFormGroup.controls.currentPassword.value;
    const newPassword = passwordFormGroup.controls.confirmPassword.value;

    this.users.checkCurrentPassword(currentPassword, this.isLocalLogin).then((currentPasswordExists) => {
      if (passwordFormGroup.valid && currentPasswordExists) {
        this.users.updatePassword(newPassword, currentPassword).then((saved) => {
          if (saved) {
            this.message = 'Your password was updated';
            this.passwordSettingsForm.resetForm();
            this.isLocalLogin = true;
          } else {
            this.message = 'An error occured while trying to update your password';
          }
          this.openSnackBar(this.message);
        }).catch();
      } else {
        if (!currentPasswordExists) {
          this.passwordFormGroup.controls['currentPassword'].setErrors({ incorrect: true });
        }
      }
    }).catch();
  }

  toggleShowToken() {
    if (!this.showToken) {
      this.auth.getAccessToken().then((token) => {
        this.apiToken = token;
        this.showToken = true;
        this.apiFormGroup.controls.apiToken.setValue(this.apiToken);
      }).catch((error) => {
        console.log(error);
      }).catch(error => {
        console.log(error);
      });
    } else if (this.showToken && this.apiToken !== '') {
      this.copyToken();
    }
  }

  copyToken() {
    navigator.clipboard.writeText(this.apiToken).then(() => {
      this.openSnackBar('Token copied to clipboard');
    }).catch(() => {
      this.openSnackBar('Error copying token to clipboard');
    });
  }

  deleteToken() {
    this.auth.deleteAccessToken().then((deleted) => {
      if (deleted) {
        this.apiToken = '';
        this.showToken = false;
        this.apiFormGroup.controls.apiToken.setValue(this.apiToken);
        this.openSnackBar('Token deleted');
      } else {
        this.openSnackBar('Error deleting token');
      }
    }).catch();
  }

}
