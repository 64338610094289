import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UserIsNotLoggedIn implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(): boolean {
    if (this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/dashboard').catch();
      return false;
    } else {
      return true;
    }
  }
}
