import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG } from '../app.config';

export interface INotificationRecipient {
  _id?: string;
  email: string;
  name: string;
  userId: string;
  notificationTimes?: any;
}

@Injectable({
  providedIn: 'root'
})

export class NotificationRecipientService {
  constructor(private http: HttpClient) {}

  setNotificationRecipient(notificationRecipient: INotificationRecipient): Promise<any> {
    return this.http.post(CONFIG.ENDPOINTS.NOTIFICATION_RECIPIENT, notificationRecipient).toPromise().then((recipient: { saved: boolean; updated: boolean; id: string }) => {
      return recipient;
    }).catch(() => {
      return false;
    });
  }

  getNotificationRecipient(): Promise<any> {
    return this.http.get(`${CONFIG.ENDPOINTS.NOTIFICATION_RECIPIENT}`).toPromise().then((recipient: { data: any }) => recipient.data).catch(() => {
      return false;
    });
  }

  deleteNotificationRecipient(recipientId: string): Promise<any> {
    return this.http.delete(`${CONFIG.ENDPOINTS.NOTIFICATION_RECIPIENT}/${recipientId}`).toPromise().then((recipient: { deleted: boolean }) => {
      return recipient.deleted;
    }).catch(() => {
      return false;
    });
  }

  optOutMessenger(recipientId: string): Promise<{ successful: boolean }> {
    return this.http.put(`${CONFIG.ENDPOINTS.NOTIFICATION_RECIPIENT}/opt-out-messenger/${recipientId}`, { user_ref: undefined }).toPromise().then((response: { updated: boolean }) => {
      return { successful: response.updated };
     }).catch(() => {
      return { successful: false };
    });
  }
}
