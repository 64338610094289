import { Pipe, PipeTransform } from '@angular/core';
import { MemberService } from '../services/member.service';

@Pipe({ name: 'DaysLeft', pure: false })
export class DaysLeft implements PipeTransform {
  constructor(private memberService: MemberService) {
  }

    transform(value: any): any {
        return this.memberService.getDifferenceInDays(value);
    }
}
