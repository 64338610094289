import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: boolean;
  @Output() loggedOutEvent: EventEmitter<boolean>;

  constructor(private router: Router, private http: HttpClient) {
    this.loggedIn = !!localStorage.getItem('loggedIn');
    this.loggedOutEvent = new EventEmitter();
  }

  reset(): void {
    localStorage.removeItem('loggedIn');
    this.loggedIn = false;
  }

  setLoggedIn(loggedIn: boolean): void {
    localStorage.setItem('loggedIn', 'true');
    this.loggedIn = loggedIn;
  }

  isLoggedIn(): boolean {
    return this.loggedIn ? this.loggedIn : !!localStorage.getItem('loggedIn');
  }

  logout(): void {
    this.http.get(`${CONFIG.ENDPOINTS.AUTH}/logout`).toPromise().then(() => {
      this.reset();
      this.loggedOutEvent.emit(true);
      this.router.navigate(['/']).catch();
    }).catch();
  }

  getAccessToken(): Promise<string> {
    return this.http.get(`${CONFIG.ENDPOINTS.AUTH}/access-token`).toPromise().then((response: { accessToken: string }) => {
      if (response.accessToken) {
        return response.accessToken;
      }
      return '';
    }).catch(() => {
      return 'Promise Rejected';
    });
  }

  deleteAccessToken(): Promise<boolean> {
    return this.http.delete(`${CONFIG.ENDPOINTS.AUTH}/access-token`).toPromise().then(() => {
      return true;
    }).catch(() => {
      return false;
    });
  }

}
