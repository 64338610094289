import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class UserIsAdmin implements CanActivate {
  constructor(private user: UserService) {}

  canActivate(): Promise<boolean> {
    return this.user.isAdmin().then(isAdmin => {
      return isAdmin;
    });
  }
}
