import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG } from '../../app.config';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  template: `
    <div class="wrapper">
      <img class="logo" src="../../../assets/yearsy-logo.svg">
      <div class="form-wrapper">
        <a [attr.href]="facebookAuthEndpoint" class="pure-button facebook-login-button">
          <img src="assets/facebook-logo-active.svg" class="facebook-button-logo">
          <span class="facebook-button-text">Log in with Facebook</span>
        </a>
        <form (keyup.enter)="localLogin(email, password)" autocomplete="on">
          <fieldset class="form-fieldset">
            <div class="form-input">
              <input type="email" required class="form-control" id="email" name="email" placeholder="Email" autoComplete="username" [(ngModel)]="email">
            </div>
            <div class="form-input">
              <input type="password" class="form-control" id="password" name="password" aria-placeholder="Password" placeholder="Password" autoComplete="current-password" [(ngModel)]="password">
            </div>
            <div class="login-error error-message" *ngIf="loginError">{{loginErrorMessage}}</div>
            <a class="pure-button form-button" (click)="localLogin(email, password)">Login</a>
          </fieldset>
        </form>
      </div>
      <p>Don't have an account yet? <a class="sign-up-link" [routerLink]="['/sign-up']">Sign Up</a></p>
      <div class="halfpage-background"></div>
    </div>
  `,
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  public email: string;
  public password: string;
  public facebookAuthEndpoint: string;
  public loginError: boolean;
  public loginErrorMessage: string;

  constructor(private user: UserService, private router: Router) {
    this.facebookAuthEndpoint = CONFIG.ENDPOINTS.FACEBOOK_LOGIN;
    this.loginError = false;
  }

  localLogin(email: string, password: string) {
    this.user.localLogin(email, password).then((loggedIn) => {
      if (loggedIn.status) {
        return this.router.navigate(['/dashboard']).catch();
      } else {
        this.loginError = true;
        this.loginErrorMessage = loggedIn.message || 'Your login failed';
      }
    }).catch((error) => {
      this.loginError = error;
      this.loginErrorMessage = error.message || 'Your login failed';
    });
  }
}
