import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

const ESCAPE_KEY = 27;

@Component({
  selector: 'app-confirmation-dialog',
  template: `
  <div [ngClass]="{ 'modal-visible': modalVisible || cancelModalVisible }" class="modal-wrapper">
      <div class="modal" id="modal">
        <div class="modal-label">{{title}}<br>{{description}}</div>
        <div class="content">
          <p *ngIf="prompt">{{prompt}}</p>
          <form #editData="ngForm" class="pure-form pure-form-aligned">
            <div class="modal-buttons">
              <button #confirmSecondButton [ngClass]="{'show-second': showSecondButton}" class="pure-button confirm confirm-second">{{ confirmSecondButtonLabel }}</button>
              <button #confirmButton class="pure-button confirm">{{ confirmButtonLabel }}</button>
              <button #declineButton class="pure-button cancel">Cancel</button>
            </div>
          </form>
        </div>
      </div>
  </div>
  `,
  styles: [`
    .modal-wrapper {
      display: none;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      color: #4a4a4a;
      background: rgba(0,0,0,0.3);
      font-weight: 300;
      font-size: 0.7em;
    }
    .modal {
      width: 80%;
      max-width: 40rem;
      height: auto;
      text-align: center;
      vertical-align: middle;
      background: #ffffff;
    }
    .modal-visible {
      display: flex;
    }
    .modal-label {
      padding: 1.4rem 0 1.1rem;
      border: solid #eee;
      border-width: 0 0 0.0625rem;
      font-weight: 300;
      letter-spacing: 0.04375em;
    }
    .content {
      margin: 1.5rem auto 2rem;
    }
    .content input {
      width: 80%;
      max-width: 20rem;
      margin: auto;
    }
    .content p {
      margin: 0 1rem 2rem;
    }
    .modal-buttons {
      margin: 0.5rem 0 0 0;
    }
    .pure-button {
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0.25rem;
      padding: 0.7rem 2rem;
      font-size: 0.9rem;
      display: inline-block;
      color: #ffffff;
      background: #9b9b9b;
      box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.1);
    }
    .confirm {
      background: var(--yearsyDarkBlue);
    }
    .confirm-second {
      display: none;
    }
    .confirm-second.show-second {
      display: block;
    }
    .decline {
      background: #da544d;
    }
    .cancel {
      margin-left: 0.2rem;
    }
  `]
})
export class ConfirmationDialogComponent implements OnChanges {
  @Input() title: string;
  @Input() description: string;
  @Input() prompt: string;
  @Input() modalVisible: boolean;
  @Input() cancelModalVisible: boolean;
  @Input() confirmButtonLabel: string;
  @Input() confirmSecondButtonLabel: string;

  @Output() confirmed = new EventEmitter<Object>();

  @ViewChild('confirmButton', { static: true }) confirmButtonElement: ElementRef;
  @ViewChild('confirmSecondButton', { static: true }) confirmSecondButtonElement: ElementRef;
  @ViewChild('declineButton', { static: true }) declineButtonElement: ElementRef;

  public showSecondButton: boolean;

  constructor() {
    this.showSecondButton = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.confirmSecondButtonLabel !== null && this.confirmSecondButtonLabel !== undefined) {
      this.showSecondButton = true;
    }

    if (changes['modalVisible'] && this.modalVisible || changes['cancelModalVisible'] && this.cancelModalVisible) {
      this.declineButtonElement.nativeElement.onclick = (() => {
        this.modalVisible = false;
        this.cancelModalVisible = false;
        this.confirmed.emit({ confirmed: false, button: 'cancel' });
    });

      this.confirmButtonElement.nativeElement.onclick = (() => {
        this.modalVisible = false;
        this.cancelModalVisible = false;
        this.confirmed.emit({ confirmed: true, button: 'confirm' });
      });

      if (this.showSecondButton) {
        this.confirmSecondButtonElement.nativeElement.onclick = (() => {
          this.modalVisible = false;
          this.cancelModalVisible = false;
          this.confirmed.emit({ confirmed: true, button: 'secondConfirm' });
        });
      }

      document.onkeyup = (event: KeyboardEvent) => {
        if (event.which === ESCAPE_KEY) {
          this.modalVisible = false;
          this.cancelModalVisible = false;
          this.confirmed.emit({ confirmed: false, button: 'escape' });
        }
      };
    }
  }
}
