export const CONFIG = {
  ENDPOINTS: {
    AUTH: '/api/v1/auth',
    FACEBOOK_LOGIN: '/api/v1/auth/facebook',
    LOCAL_LOGIN: '/api/v1/auth/local-login',
    LOCAL_SIGN_UP: '/api/v1/auth/local-sign-up',
    NOTIFICATION_RECIPIENT: '/api/v1/notification-recipients',
    NOTIFICATIONS: '/api/v1/notifications',
    MEMBERS: '/api/v1/members',
    USERS: '/api/v1/users'
  },
  USER_ROLES: ['member', 'admin', 'superuser'],
  PROBATION_PERIODS: [
    { value: 0, viewValue: 'No probation' },
    { value: 1, viewValue: '1 Month' },
    { value: 2, viewValue: '2 Months' },
    { value: 3, viewValue: '3 Months' },
    { value: 4, viewValue: '4 Months' },
    { value: 5, viewValue: '5 Months' },
    { value: 6, viewValue: '6 Months' }
  ],
  GENDERS: [
    { value: 'Female', viewValue: 'Female' },
    { value: 'Male', viewValue: 'Male' }
  ],
  XLSX_TITLES: [
    '_id',
    'name',
    'birthday',
    'workdayAnniversary',
    'customAnniversary',
    'department',
    'email',
    'gender',
    'location',
    'probationPeriod',
    'employmentEndDate'
  ]
};
