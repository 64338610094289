import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { CONFIG } from '../../app.config';
import { IMember, MemberService } from '../../services/member.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-team-list',
  template: `
  <div *ngIf="!canRender" class="push">
  </div>
  <div *ngIf="canRender" [@fadeIn]="'in'" id="fade-in" class="page-wrapper">
    <div class="row info-card-row">
      <app-info-card class="blue-header" [cardHeader]="'Number of team members'" [cardContent]="noOfActiveMembers" #infoCard></app-info-card>
      <app-info-card class="purple-header" [cardHeader]="'Average employment time'" [cardContent]="averageEmploymentTimeActive" #infoCard></app-info-card>
      <app-info-card class="green-header" [cardHeader]="'Average employee age'" [cardContent]="averageAgeActive" #infoCard></app-info-card>
      <app-info-card class="yellow-header" [cardHeader]="'Gender ratio'" [cardContent]="genderRatio" [cardInfo]="genderRatioInfo" #infoCard></app-info-card>
    </div>
    <div class="team-table-title d-flex">
      <h2>Your Team</h2>
      <img src="../../../assets/download.svg" (click)="generateXLXS()" alt="download">
    </div>
    <div class="hr"></div>
    <div class="table-switch-container">
      <label class="switch table-switch">
        <input type="checkbox" [checked]="showInactiveTeamMembers" (change)="toggleShowInactive($event)">
        <span class="slider round"></span>
      </label>
      <span class="switch-label">Show inactive members</span>
    </div>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr class="d-flex">
            <th class="edit table-cell"></th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('name')">Full Name</h3>
              <span *ngIf="this.sortField === 'name'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('birthday')">Birthday</h3>
              <span *ngIf="this.sortField === 'birthday'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('workdayAnniversary')">Starting day</h3>
              <span  *ngIf="this.sortField === 'workdayAnniversary'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('customAnniversary')">Probation time</h3>
              <span  *ngIf="this.sortField === 'customAnniversary'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell department-cell">
              <h3 (click)="sortTeamList('department')">Department</h3>
              <span  *ngIf="this.sortField === 'department'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell email-cell">
              <h3 (click)="sortTeamList('email')">Email</h3>
              <span  *ngIf="this.sortField === 'email'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('gender')">Gender</h3>
              <span  *ngIf="this.sortField === 'gender'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('location')">Location</h3>
              <span  *ngIf="this.sortField === 'location'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
            <th class="table-cell">
              <h3 (click)="sortTeamList('employmentEndDate')">Ended date</h3>
              <span  *ngIf="this.sortField === 'employmentEndDate'" [ngClass]="{ 'ascending' : this.sortOrder === 'ascending', 'descending' : this.sortOrder === 'descending' }"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <div class="error" *ngIf="error">
            <p>{{error}}</p>
          </div>
          <tr class="d-flex" *ngFor="let teamMember of teamMembers | paginate: paginatorConfig; let i = index" [ngClass]="teamMember.employmentEndDate !== null && teamMember.employmentEndDate !== undefined ? 'anonymized-member' : 'active-member'">
            <td class="edit table-cell">
              <div class="edit-wrapper">
                <div class="list-icon" *ngIf="editMode === false || rowToEdit !== i">
                  <img (click)="editMode = true; rowToEdit = i" src="../../../assets/edit-vector.svg" alt="edit">
                </div>
                <div class="list-icon" *ngIf="editMode === false || rowToEdit !== i">
                  <img (click)="openConfirmationModal(teamMember, i)" src="../../../assets/trashcan-vector.svg" alt="remove">
                </div>
                <div class="list-icon" *ngIf="editMode && rowToEdit === i" (click)="saveTeamMember(teamMember)">
                  <img src="../../../assets/check-solid.svg" alt="edit">
                </div>
                <div class="list-icon" *ngIf="editMode && rowToEdit === i">
                  <img (click)="openCancelConfirmationModal()" src="../../../assets/times-solid.svg" alt="remove">
                </div>
              </div>
            </td>
            <td class="table-cell">
            <mat-form-field hintLabel="Min 5 characters" *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)">
              <mat-label>Full Name</mat-label>
              <input [(ngModel)]="teamMember.name" matInput required minlength="5">
            </mat-form-field>
              <p class="table-paragraph" *ngIf="i !== rowToEdit">
                {{ teamMember.name }}
              </p>
            </td>
            <td class="table-cell">
              <mat-form-field hintLabel="mm-dd-yyyy" *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)">
                <mat-label>Birthday</mat-label>
                <input [(ngModel)]="teamMember.birthday" matInput [matDatepicker]="birthday">
                <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
                <mat-datepicker #birthday></mat-datepicker>
              </mat-form-field>
              <div class=" d-flex">
                <p class="table-paragraph" *ngIf="i !== rowToEdit">
                  {{ teamMember.birthday | date:'yyyy-MM-dd' }}
                </p>
                <p *ngIf="i !== rowToEdit && teamMember.birthday" class ="small-info">
                (in {{ teamMember.birthday | DaysLeft }} days)
                </p>
              </div>
            </td>
            <td class="table-cell">
              <mat-form-field hintLabel="mm-dd-yyyy" *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)">
                <mat-label>Starting Day</mat-label>
                <input [(ngModel)]="teamMember.workdayAnniversary" matInput [matDatepicker]="startingDay">
                <mat-datepicker-toggle matSuffix [for]="startingDay"></mat-datepicker-toggle>
                <mat-datepicker #startingDay></mat-datepicker>
              </mat-form-field>
              <div class="d-flex">
                <p class="table-paragraph" *ngIf="i !== rowToEdit">
                  {{ teamMember.workdayAnniversary | date:'yyyy-MM-dd' }}
                </p>
                <p *ngIf="i !== rowToEdit && teamMember.workdayAnniversary" class ="small-info">
                  (in {{ teamMember.workdayAnniversary | DaysLeft }} days)
                </p>
              </div>
            </td>
            <td class="table-cell">
              <mat-form-field *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)">
                <mat-label>Probation period</mat-label>
                <mat-select [(ngModel)]="teamMember.probationPeriod">
                  <mat-option *ngFor="let probationPeriod of probationPeriods" [value]="probationPeriod.value">
                    {{ probationPeriod.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="table-paragraph probation" *ngIf="i !== rowToEdit && teamMember.probationPeriod > 0">
                {{ teamMember.probationPeriod }} month(s)
              </p>
              <p *ngIf="i !== rowToEdit && teamMember.customAnniversary && teamMember.probationPeriod > 0" class ="small-info">
                (in {{ teamMember.customAnniversary | DaysLeft }} days)
              </p>
            </td>
            <td class="table-cell department-cell">
              <ng-select *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)"
                [items]="allDepartments"
                [addTag]="addDepartmentTag.bind(this)"
                [hideSelected]="true"
                multiple="true"
                bindLabel="name"
                [(ngModel)]="teamMember.department"
                placeholder="Department">
              </ng-select>
              <p class="table-paragraph department" *ngIf="i !== rowToEdit && teamMember.department !== undefined && teamMember.department !== null && teamMember.department.length > 0">
                <span *ngFor="let department of teamMember.department; let index = index">{{ department.name }}<span *ngIf="teamMember.department.length > 1 && index < teamMember.department.length - 1">, </span></span>
              </p>
            </td>
            <td class="table-cell email-cell">
            <mat-form-field hintLabel="Email address" *ngIf="i === rowToEdit || (teamMember.email === '' && submitted)">
              <mat-label>Email</mat-label>
              <input [(ngModel)]="teamMember.email" matInput>
            </mat-form-field>
              <p class="table-paragraph" *ngIf="i !== rowToEdit">
                <a href="mailto:{{teamMember.email}}">{{ teamMember.email }}</a>
              </p>
            </td>
            <td class="table-cell">
              <mat-form-field *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)">
                <mat-label>Gender</mat-label>
                <mat-select [(ngModel)]="teamMember.gender">
                  <mat-option *ngFor="let gender of genders" [value]="gender.value">
                    {{ gender.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="table-paragraph gender" *ngIf="i !== rowToEdit && teamMember.gender !== ''">
                {{ teamMember.gender }}
            </td>
            <td class="table-cell">
              <mat-form-field hintLabel="Location" *ngIf="i === rowToEdit || (teamMember.location === '' && submitted)">
                <mat-label>Location</mat-label>
                <input [(ngModel)]="teamMember.location" matInput>
              </mat-form-field>
              <p class="table-paragraph" *ngIf="i !== rowToEdit">
                {{ teamMember.location }}
              </p>
            </td>
            <td class="table-cell">
              <mat-form-field hintLabel="mm-dd-yyyy" *ngIf="i === rowToEdit || (teamMember.name === '' && submitted)">
                <mat-label>Ended date</mat-label>
                <input [(ngModel)]="teamMember.employmentEndDate" matInput [matDatepicker]="endingDay">
                <mat-datepicker-toggle matSuffix [for]="endingDay"></mat-datepicker-toggle>
                <mat-datepicker #endingDay></mat-datepicker>
              </mat-form-field>
              <div class="d-flex">
                <p class="table-paragraph" *ngIf="i !== rowToEdit">
                  {{ teamMember.employmentEndDate | date:'yyyy-MM-dd' }}
                </p>
              </div>
            </td>
          </tr>
          <app-confirmation-dialog [title]="'What would you like to do with this member?'" [description]="'Delete completely removes the member and all statistics tied to the member. '" [prompt]="''" [modalVisible]="modalVisible"
          [confirmButtonLabel]="'Delete'" [confirmSecondButtonLabel]="'Anonymize (save statistics)'" (confirmed)="onConfirmed($event)"></app-confirmation-dialog>
          <app-confirmation-dialog [title]="'Are you sure you want to cancel without saving?'" [prompt]="''" [cancelModalVisible]="cancelModalVisible"
          [confirmButtonLabel]="'Confirm'" (confirmed)="onConfirmedCancelChanges($event)"></app-confirmation-dialog>
        </tbody>
      </table>
    </div>
    <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
    <app-new-team-member [allDepartments]="allDepartments" [addDepartmentTag]="addDepartmentTag" (onGetMembers)="getMembers()"></app-new-team-member>
    <app-team-graphs [activeTeamMembers]="activeTeamMembers" [teamMembers]="teamMembers"></app-team-graphs>
  `,
  styleUrls: ['./team-list.component.css'],
  animations: [
    trigger('fadeIn', [
      state('in', style({
        opacity: 1
      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(900)
      ])
    ])
  ]
})

export class TeamListComponent implements OnInit {
  public error: string;
  public rowToEdit: number;
  public submitted: boolean;
  public modalVisible: boolean;
  public cancelModalVisible: boolean;
  public editMode: boolean;
  public index: number;
  public sortOrder: any;
  public sortField: any;
  public canRender: boolean;
  public probationPeriods: Array<any>;
  public paginatorConfig: any;
  public numberOfMembers: number;
  public averageEmploymentTime: string;
  public averageEmploymentTimeActive: string;
  public averageAge: string;
  public averageAgeActive: string;
  public numberOfMonthsList: Array<any>;
  public activeNumberOfMonthsList: Array<any>;
  public genders: Array<any>;
  public genderRatio: number;
  public genderRatioInfo: string;

  public teamMembers: Array<any>;
  private teamMember: IMember;
  public memberTitles: Array<any>;
  public showInactiveTeamMembers: boolean;
  public activeTeamMembers: Array<any>;
  public noOfActiveMembers: number;

  public allDepartments: Array<any>;


  @ViewChild('newTeamMemberForm') newTeamMemberForm;

  constructor(private member: MemberService, private snackbar: SnackbarService) {
    this.submitted = false;
    this.modalVisible = false;
    this.teamMembers = [];
    this.sortField = 'name';
    this.sortOrder = 'ascending';
    this.canRender = false;
    this.probationPeriods = CONFIG.PROBATION_PERIODS;
    this.genders = CONFIG.GENDERS;
    this.memberTitles = [];
    this.showInactiveTeamMembers = true;
    this.activeTeamMembers = [];
    this.allDepartments = [];
  }

  ngOnInit() {
    this.getMembers();
    this.paginatorConfig = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.teamMembers.length
    };
    this.memberTitles = CONFIG.XLSX_TITLES;
  }

  onPageChange(event) {
    this.paginatorConfig.currentPage = event;
  }

  toggleShowInactive(event) {
    this.showInactiveTeamMembers = event.target.checked;
    this.getMembers();
  }

  getDepartments() {
    this.allDepartments = [];
    this.teamMembers.forEach(member => {
      if (member.department !== undefined && member.department !== null) {
        member.department.forEach(department => {
          const found = this.allDepartments.some(el => el.name === department.name);
          if (!found) {
            this.allDepartments.push(department);
          }
        });
      }
    });
  }

  addDepartmentTag(name) {
    return this.allDepartments.indexOf(name) < 0 ? { name: name, tag: true } : null;
  }

  sortTeamList(sortby?: string) {
    if (sortby && this.sortField === sortby && this.sortOrder === 'ascending') {
      this.sortOrder = 'descending';
    } else if (sortby) {
      this.sortOrder = 'ascending';
    } else {
      sortby = this.sortField;
    }

    if (sortby === 'name' || sortby === 'email' || sortby === 'gender' || sortby === 'location') {
      this.teamMembers.sort((a, b) => {
        if (a[sortby] === undefined || a[sortby] === null) {
          return 1;
        } else if (b[sortby] === undefined || b[sortby] === null) {
          return -1;
        }
        if (this.sortOrder === 'ascending' && a[sortby] !== undefined && a[sortby] !== null) {
          return a[sortby].localeCompare(b[sortby]);
        } else if (this.sortOrder === 'descending' && b[sortby] !== undefined && b[sortby] !== null) {
          return b[sortby].localeCompare(a[sortby]);
        }
      });
    } else {
      this.teamMembers.sort((a, b) => {
        const dateA = a[sortby] ? new Date(a[sortby]) : null;
        const dateB = b[sortby] ? new Date(b[sortby]) : null;
        if (sortby === 'customAnniversary') {
          if (dateA === null) {
            return 1;
          } else if (dateB === null) {
            return -1;
          }
        }
        if (this.sortOrder === 'ascending') {
          return <any>dateA - <any>dateB;
        } else {
          return <any>dateB - <any>dateA;
        }
      });
    }
    this.sortField = sortby;
  }

  getMembers() {
    this.member.getMembers(this.showInactiveTeamMembers).then((memberResponse) => {
      if (memberResponse) {
        this.teamMembers = memberResponse;
        this.activeTeamMembers = this.getActiveTeamMembers(this.teamMembers);
        this.noOfActiveMembers = this.activeTeamMembers.length;
        this.sortTeamList();
        this.numberOfMembers = this.teamMembers.length;
        this.canRender = true;
        this.calculateAverageTimeInYears('birthday');
        this.calculateAverageTimeInYears('workdayAnniversary');
        this.calculateGenderRatio('gender');
        this.getDepartments();
      } else {
        this.canRender = false;
      }
    }).catch(() => {
      this.error = 'Could not get the members.';
    });
  }

  calculateMonths(eventType) {
    this.numberOfMonthsList = [];
    this.activeNumberOfMonthsList = [];

    this.teamMembers.forEach((member) => {
      const months = this.member.getDifferenceInMonths(member[eventType]);
      if (months !== 0) {
        this.numberOfMonthsList.push(months);
      }
    });

    this.activeTeamMembers.forEach((member) => {
      const months = this.member.getDifferenceInMonths(member[eventType]);
      if (months !== 0) {
        this.activeNumberOfMonthsList.push(months);
      }
    });
  }

  calculateAverageTimeInYears(eventType: string) {
    let averageMonths = 0;
    let avarageMonthsActive = 0;

    this.calculateMonths(eventType);

    if (this.numberOfMonthsList.length > 0) {
      averageMonths = this.numberOfMonthsList.reduce((accumulator: number, result: number) => accumulator + result, 0) / this.numberOfMonthsList.length;
    }
    if (this.activeNumberOfMonthsList.length > 0) {
      avarageMonthsActive = this.activeNumberOfMonthsList.reduce((accumulator: number, result: number) => accumulator + result, 0) / this.activeNumberOfMonthsList.length;
    }
    if (eventType === 'birthday') {
      this.averageAge = `${(averageMonths / 12).toFixed(1)} years`;
      this.averageAgeActive = `${(avarageMonthsActive / 12).toFixed(1)} years`;
    } else if (eventType === 'workdayAnniversary') {
      this.averageEmploymentTime = `${(averageMonths / 12).toFixed(1)} years`;
      this.averageEmploymentTimeActive = `${(avarageMonthsActive / 12).toFixed(1)} years`;
    }
  }

  calculateGenderRatio = (eventType: string) => {
    if (eventType === 'gender') {
      let female = 0;
      let male = 0;
      let genderNotApplicable = 0;
      let missingGender = 0;

      this.activeTeamMembers.map((teamMember) => {
        const genderFilter = Object.fromEntries(Object.entries(teamMember).filter(([key]) => key.includes('gender')));
        if (genderFilter['gender'] !== null && genderFilter['gender'] !== undefined) {
          if (genderFilter['gender'] === 'Female') {
            female++;
          } else if (genderFilter['gender'] === 'Male') {
            male++;
          } else {
            genderNotApplicable++;
          }
        } else {
          missingGender++;
        }
      });

      this.genderRatio = ((female === 0 && male === 0) ? 0 : ((female > 0 && male === 0) ? female : female / male));
      this.genderRatioInfo = ((missingGender > 0 || genderNotApplicable > 0) ? `${genderNotApplicable + missingGender} team member(s) is missing valid gender info.` : null);

    }
  }

  generateXLXS() {
    const exportXLXS = [...this.teamMembers].map((teamMember) => {
      this.memberTitles.forEach((title) => {
        if (!teamMember.hasOwnProperty(title)) {
          teamMember[title] = null;
        }
      });
      const sortedExportValues = JSON.parse(JSON.stringify(teamMember, this.memberTitles, 4));
      return Object.keys(sortedExportValues).map((key) => {
        if ((key === 'birthday' || key === 'workdayAnniversary' || key === 'customAnniversary' || key === 'employmentEndDate') && sortedExportValues[key] !== null) {
          return sortedExportValues[key].split('T')[0];
        } else if (key !== '_id') {
          if (key === 'department') {
            let departmentTags = '';
            if (sortedExportValues[key] !== null) {
              sortedExportValues[key].forEach((department, index) => {
                departmentTags += index > 0 ? `, ${department.name}` : department.name;
              });
            }
            return departmentTags;
          }
          return sortedExportValues[key];
        }
      });
    });

    exportXLXS.unshift(this.memberTitles);
    const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportXLXS);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
    XLSX.writeFile(workBook, 'YearsyExport.xlsx');
  }

  saveTeamMember(member, finished = <boolean>true): Promise<any> {
    this.submitted = true;
    this.rowToEdit = null;
    if (member._id) {
      return this.member.updateMember(member).then(() => {
        if (finished) {
          this.getMembers();
          this.snackbar.message = `${member.name}'s information has been updated`;
          this.snackbar.openSnackBar();
        }
      }).catch();
    } else {
      return this.member.addMember(member).then(() => {
        this.newTeamMemberForm.resetForm();
        if (finished) {
          this.getMembers();
          this.snackbar.message = `${member.name} has been added to your team`;
          this.snackbar.openSnackBar();
        }
      }).catch();
    }
  }

  onConfirmed(event: Object) {
    this.modalVisible = false;
    if (event['confirmed']) {
      if (event['button'] === 'confirm') {
        this.deleteTeamMember(this.teamMember);
      } else if (event['button'] === 'secondConfirm') {
        this.anonymizeTeamMember(this.teamMember).catch();
      }
    }
  }

  onConfirmedCancelChanges(event: Object) {
    this.cancelModalVisible = false;
    if (event['confirmed']) {
      this.editMode = false;
      this.rowToEdit = null;
      this.getMembers();
    }
  }

  openConfirmationModal(teamMember: IMember, index: number) {
    this.modalVisible = true;
    this.teamMember = teamMember;
    this.index = index;
  }

  openCancelConfirmationModal() {
    this.cancelModalVisible = true;
  }

  deleteTeamMember(member: IMember) {
    if (member._id) {
      this.member.deleteMember(member._id).then((deleted) => {
        if (deleted) {
          this.getMembers();
          this.snackbar.message = `${member.name} has been completely removed from your team`;
          this.snackbar.openSnackBar();
        } else {
          this.error = 'Could not delete the user.';
        }
      }).catch();
    }
    this.getMembers();
  }

  anonymizeTeamMember(member: IMember, finished = <boolean>true): Promise<any> {
    if (member._id) {
      const memberNameTemp = member.name;
      member.name = null;
      member.email = null;
      member.birthday = null;
      member.employmentEndDate = member.employmentEndDate !== null && member.employmentEndDate !== undefined ? member.employmentEndDate : new Date();
      return this.member.updateMember(member).then(() => {
        if (finished) {
          this.getMembers();
          this.snackbar.message = `${memberNameTemp}'s information has been anonymized and saved for statistics`;
          this.snackbar.openSnackBar();
        }
      }).catch();
    }
    this.getMembers();
  }

  getActiveTeamMembers(teamMembers) {
    return teamMembers.filter(member => {
      if (member.employmentEndDate === null || member.employmentEndDate === undefined) {
        return member;
      }
    });
  }

  decodeDATEVALUE(dateValue: number): Date {
    return new Date((dateValue - 25569) * 86400 * 1000);
  }

}
