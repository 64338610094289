import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG } from '../app.config';

export interface INotificationSettings {
  userId?: string;
  notificationTimes: { birthday: Array<number>; workday: Array<number>; custom: Array<number> };
}

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService {
  constructor(private http: HttpClient) {}

  getNotificationSettings(): Promise<any> {
    return this.http.get(CONFIG.ENDPOINTS.NOTIFICATIONS).toPromise().then((result: { data: any }) => {
      return result.data;
    }).catch();
  }

  setNotificationSettings(notificationSettings: INotificationSettings): Promise<any> {
    return this.http.put(CONFIG.ENDPOINTS.NOTIFICATIONS, notificationSettings).toPromise().then((result: { data: any }) => {
      return result.data;
    }).catch();
  }
}
