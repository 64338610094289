import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';
import { MemberService } from '../../services/member.service';

@Component({
  selector: 'app-team-graphs',
	template: `
	<div class="team-graph-overview" *ngIf="activeTeamMembers.length > 0">
		<h2>Time employed overview</h2>
		<div class="hr"></div>
		<div>
			<app-time-employed-graph baseChart
				[employmentTimeData]="employmentTimeData"
				[barChartLabels]="barChartLabels">
			</app-time-employed-graph>
		</div>
		<div>
			<app-average-age-graph baseChart
				[employmentAgeData]="employmentAgeData"
				[barChartLabels]="barChartLabels">
			</app-average-age-graph>
		</div>
	</div>
  <div class="team-graph-overview" *ngIf="teamMembers.length > 0">
    <div>
			<app-emloyed-stats-graph baseChart
				[employmentStatsData]="employmentStatsData"
				[employedOverTimeLabels]="employedOverTimeLabels">
			</app-emloyed-stats-graph>
		</div>
    <div>
			<app-employment-started-graph baseChart
				[employmentStartedData]="employmentStartedData"
				[startDateLabels]="startDateLabels">
			</app-employment-started-graph>
		</div>
  </div>
	`,
	styles: [`
		.hr {
			margin-bottom: 0;
		}

		.team-graph-overview {
			padding-top: 1rem;
			max-width: 1080px;
    		margin: 0 auto;
		}
	`]
})
export class TeamGraphsComponent implements OnInit, OnChanges {
  public barChartLabels: Array<string>;
  public employedOverTimeLabels: Array<string>;
  public startDateLabels: Array<string>;
	public employmentTimeData: Array<{ data: Array<any>; members: Array<any>; backgroundColor: string; borderColor: string; hoverBackgroundColor: string}>;
	public employmentAgeData: Array<{ data: Array<any>; members: Array<any>; backgroundColor: string; borderColor: string; hoverBackgroundColor: string}>;
	public employmentStatsData: Array<{ data: Array<any>; members: Array<any>; backgroundColor: string; borderColor: string; hoverBackgroundColor: string; pointBackgroundColor: string}>;
	public employmentStartedData: Array<{ data: Array<any>; members: Array<any>; backgroundColor: string; borderColor: string; hoverBackgroundColor: string}>;
	public employeesList: Array<any>;
  public allEmployeesList: Array<any>;
  public startDates: Array<any>;

	@Input() teamMembers: Array<any>;
  @Input() activeTeamMembers: Array<any>;

  constructor(private member: MemberService) {
    this.employeesList = [];
    this.allEmployeesList = [];
    this.startDates = [];
    this.employmentTimeData = [
			{
        data: [],
        members: [],
        backgroundColor: 'rgb(180, 30, 243, 0.6)',
        borderColor: 'rgb(180, 30, 243, 1)',
        hoverBackgroundColor: 'rgb(180, 30, 243, 0.8)'
      }
		];
    this.employmentAgeData = [
			{
				data: [],
				members: [],
        backgroundColor: 'rgb(43, 88, 236, 0.6)',
				borderColor: 'rgb(43, 88, 236, 1)',
				hoverBackgroundColor: 'rgb(43, 88, 236, 0.8)'
      }
		];

    this.employmentStatsData = [
			{
				data: [],
				members: [],
        backgroundColor: 'rgb(0, 171, 170, 0.6)',
        borderColor: 'rgb(0, 171, 170, 1)',
        hoverBackgroundColor: 'rgb(0, 171, 170, 0.8)',
				pointBackgroundColor: 'rgb(0, 171, 170, 1)'
			}
		];

    this.employmentStartedData = [
			{
				data: [],
				members: [],
				backgroundColor: 'rgb(242, 195, 68, 0.8)',
				borderColor: 'rgb(242, 195, 68, 1)',
				hoverBackgroundColor: 'rgb(242, 195, 68, 1)'
			}
		];

    this.barChartLabels = [
			'0-6 Months',
			'6 months - 2 years',
			'2-4 years',
			'4+ years'
		];

    this.employedOverTimeLabels = [];
    this.startDateLabels = [];
	}

  ngOnInit() {
    this.resetObjects();
    this.getEmployeesListData();
    this.getAllEmployeesListData();
    this.getStartDates();
  }

	ngOnChanges() {
    this.resetObjects();
    this.getEmployeesListData();
    this.getAllEmployeesListData();
    this.getStartDates();
	}

	resetObjects() {
    this.employeesList = [];
    this.allEmployeesList = [];
    this.startDates = [];
    this.employedOverTimeLabels = [];
    this.startDateLabels = [];
    this.employmentTimeData = [
			{
        data: [],
        members: [],
        backgroundColor: 'rgb(180, 30, 243, 0.6)',
        borderColor: 'rgb(180, 30, 243, 1)',
        hoverBackgroundColor: 'rgb(180, 30, 243, 0.8)'
      }
		];
    this.employmentAgeData = [
			{
        data: [],
        members: [],
        backgroundColor: 'rgb(43, 88, 236, 0.6)',
				borderColor: 'rgb(43, 88, 236, 1)',
				hoverBackgroundColor: 'rgb(43, 88, 236, 0.8)'
      }
		];
    this.employmentStatsData = [
			{
        data: [],
        members: [],
        backgroundColor: 'rgb(0, 171, 170, 0.6)',
        borderColor: 'rgb(0, 171, 170, 1)',
        hoverBackgroundColor: 'rgb(0, 171, 170, 0.8)',
				pointBackgroundColor: 'rgb(0, 171, 170, 1)'
      }
		];
    this.employmentStartedData = [
			{
        data: [],
        members: [],
        backgroundColor: 'rgb(242, 195, 68, 0.8)',
        borderColor: 'rgb(242, 195, 68, 1)',
        hoverBackgroundColor: 'rgb(242, 195, 68, 1)'
      }
		];
	}

  getEmployeesListData() {
    this.activeTeamMembers.forEach((member) => {
      const monthsEmployed = this.member.getDifferenceInMonths(member['workdayAnniversary']);
      const age = this.member.getDifferenceInMonths(member['birthday']) / 12;
      const name = member.name;
      const startDate = member.workdayAnniversary;
      this.employeesList.push({ monthsEmployed, age, name, startDate });
    });
    this.getMonthRange(0, 6);
    this.getMonthRange(6, 24);
    this.getMonthRange(24, 48);
    this.getMonthRange(48);
	}

  getAllEmployeesListData() {
    this.teamMembers.forEach((member) => {
      const monthsEmployed = this.member.getDifferenceInMonths(member['workdayAnniversary']);
      const age = this.member.getDifferenceInMonths(member['birthday']) / 12;
      const name = member.name;
      const startDate = member.workdayAnniversary;
      const endDate = member.employmentEndDate;
      this.allEmployeesList.push({ monthsEmployed, age, name, startDate, endDate });
    });
    if (this.allEmployeesList.length > 0) {
      this.getAllEmploymentsDateRange();
    }
  }

  getStartDates() {
    this.employeesList.forEach(employee => {
      const leapDate: Date = new Date(employee.startDate);
      if (dateFns.isLeapYear(employee.startDate) && leapDate.getMonth() === 1 && leapDate.getDate() === 29) {
        employee.startDate = new Date(leapDate.getTime() - (1 * 24 * 60 * 60 * 1000));
      }
      this.startDates.push(employee.startDate);
    });
    if (this.startDates.length > 0) {
      this.startDates.sort((a, b) => {
        const da = a ? new Date(a) : null;
        const db = b ? new Date(b) : null;
        return <any>da - <any>db;
      });
      this.setGraphDateInterval(this.calculateStartDatesByMonth(this.startDates), 'startDate');
    }
  }

  getAllEmploymentsDateRange() {
    const employeeDates = [];
    this.allEmployeesList.forEach(employee => {
      const startDate = employee.startDate;
      const endDate = employee.endDate;
      employeeDates.push({ startDate, endDate });
    });
    employeeDates.sort((a, b) => {
      const da = a['startDate'] ? new Date(a['startDate']) : null;
      const db = b['startDate'] ? new Date(b['startDate']) : null;
      return <any>da - <any>db;
    });

    this.setGraphDateInterval(this.calculateEmployeesByMonth(employeeDates), 'totalEmployees');
  }

  setGraphDateInterval(employeesPerMonth, type) {
    if (employeesPerMonth !== undefined) {
      employeesPerMonth.forEach(month => {
        // tslint:disable-next-line restrict-plus-operands
        const realMonth = month.startDate.getMonth() + 1;
        const monthNumber = (`0${realMonth}`).slice(-2);
        const date = (`0${month.startDate.getDate()}`).slice(-2);
        const startDate = `${month.startDate.getFullYear()}-${monthNumber}-${date}`;
        const dateLabel = startDate.slice(0, 7);
        if (type === 'totalEmployees') {
          this.employmentStatsData[0].data.push(month.employees);
          this.employedOverTimeLabels.push(dateLabel);
        } else if (type === 'startDate') {
          this.employmentStartedData[0].data.push(month.employees);
          this.startDateLabels.push(dateLabel);
        }
      });
    }
  }

  calculateStartDatesByMonth(startDates) {
    const monthlyStart = [];
    const firstDateFound = new Date(startDates[0]);
    const startDate = new Date(firstDateFound.getFullYear(), firstDateFound.getMonth(), 1);
    const lastDateFound = new Date(startDates[startDates.length - 1]);
    let activeMonth = startDate;
    for (let i = 0; i <= this.monthDiff(startDate, lastDateFound); i++) {
      const endDate = new Date(activeMonth.getFullYear(), activeMonth.getMonth() + 1, 0);
      const noOfemployees = this.getNumberOfStartDates(activeMonth, endDate, startDates);
      monthlyStart.push({ startDate: activeMonth, endDate: endDate, employees: noOfemployees });
      activeMonth = this.getNextMonth(activeMonth);
    }
    return monthlyStart;
  }

  getNumberOfStartDates(startDate, endDate, employeeDates) {
    let noOfEmployees = 0;
    employeeDates.forEach(employeeDate => {
      const employeeStart = employeeDate !== undefined ? new Date(employeeDate) : undefined;
      if (employeeStart >= startDate && employeeStart <= endDate) {
        noOfEmployees++;
      }
    });
    return noOfEmployees;
  }

  calculateEmployeesByMonth(employeeDates) {
    const monthlyEmployeeStats = [];
    const firstDateFound = new Date(employeeDates[0].startDate);
    const startDate = new Date(firstDateFound.getFullYear(), firstDateFound.getMonth() + 1, 1);
    let activeMonth = startDate;
    for (let i = 0; i < this.monthDiff(startDate, new Date()); i++) {
      const endDate = new Date(activeMonth.getFullYear(), activeMonth.getMonth() + 1, 0);
      const noOfemployees = this.getNumberOfEmployees(activeMonth, endDate, employeeDates);
      monthlyEmployeeStats.push({ startDate: activeMonth, endDate: endDate, employees: noOfemployees });
      activeMonth = this.getNextMonth(activeMonth);
    }
    return monthlyEmployeeStats;
  }

  getNumberOfEmployees(startDate, endDate, employeeDates) {
    let noOfEmployees = 0;
    employeeDates.forEach(employee => {
      const employeeEnd = employee.endDate !== undefined ? new Date(employee.endDate) : undefined;
      const employeeStart = employee.startDate !== undefined ? new Date(employee.startDate) : undefined;
      if (employeeEnd === undefined || employeeEnd >= endDate) {
        if (employeeStart <= startDate) {
          noOfEmployees++;
        }
      }
    });
    return noOfEmployees;
  }

  getNextMonth(currentMonth) {
    if (currentMonth.getMonth() === 11) {
      // tslint:disable-next-line restrict-plus-operands
      return new Date(currentMonth.getFullYear() + 1, 0);
    } else {
      // tslint:disable-next-line restrict-plus-operands
      return new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
    }
  }

  monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

	getMonthRange(lower, upper = 0, activeMembers = true) {
    const listToUse = activeMembers === true ? this.employeesList : this.allEmployeesList;
    const employees = listToUse.filter(employee => {
			if (employee.monthsEmployed > lower && (employee.monthsEmployed <= upper || !upper)) {
				return {
					monthsEmployed: employee.monthsEmployed,
					age: employee.age,
					name: employee.name
				};
			}
		});
    this.setChartData(employees, activeMembers);
	}

	setChartData(employees, activeMembers) {
    if (activeMembers === true) {
      this.employmentTimeData[0].data.push(employees.length);
      this.employmentAgeData[0].data.push(employees.reduce((accumulator: number, employee) => {
        return accumulator + parseInt(employee.age, 10);
      }, 0) / employees.length);
    } else {
      // this.employmentStatsData[0].data.push(employees.length);
    }
	}
}
