import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const interceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(interceptorSkipHeader)) {
      const headers = req.headers.delete(interceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    const authReq = req.clone();

    return next.handle(authReq).pipe(tap((event: HttpEvent<HttpResponse<any>>) => {
      if (event instanceof HttpResponse) {
        this.auth.setLoggedIn(true);
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 403) {
          this.auth.reset();
          location.href = '/login';
        }
      }
    }));
  }
}
