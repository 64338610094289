import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-average-age-graph',
	template: `
		<div style="display: block" class="graph">
			<canvas baseChart
				[datasets]="employmentAgeData"
				[labels]="barChartLabels"
				[options]="chartOptions"
				[legend]="false"
				[chartType]="'bar'">
			</canvas>
		</div>
	`,
	styles: [`
		.graph {
			height: 30vh;
			padding: 1rem 0;
			margin: 1rem 0;
		}
	`]
})
export class AverageAgeGraphComponent implements OnInit, OnChanges {
	public chartOptions: any;

	@Input() barChartLabels: Array<any>;
	@Input() employmentAgeData: Array<{ data: Array<any>; members: Array<any>; backgroundColor: string; borderColor: string; hoverBackgroundColor: string}>;

  constructor() {
	}

  ngOnInit() {
		this.setGraphCallbackOptions();
	}

	ngOnChanges() {
		this.setGraphCallbackOptions();
	}

	setGraphCallbackOptions() {
		this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
					label: (tooltipItem, chartData) => {
						return chartData.datasets[tooltipItem.datasetIndex].members[tooltipItem.index];
					}, title: (tooltipItem) => {
						const xLabel = tooltipItem[0].label;
						const yLabel = tooltipItem[0].yLabel.toFixed(1);
						return `${yLabel} average age employed for ${xLabel} `;
					}
				}
      },
      scales: {
        yAxes: [{
					ticks: {
						beginAtZero: true
					},
          scaleLabel: {
            display: true,
            labelString: 'Average age'
          }
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Employment time'
          }
        }]
      }
    };
	}
}
