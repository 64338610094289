import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-top-bar',
  template: `
  <header>
  <div class="menu-wrapper d-flex" #menu>
    <div class="pure-menu main-menu" #menuCanTransform>
      <div class="logo-wrapper">
        <a [routerLink]="['/dashboard']"><img class="logo" src="assets/yearsy-logo.svg"></a>
      </div>
      <ul class="pure-menu-list d-flex main-menu-list">
        <li>
          <a class="pure-menu-link" [routerLink]="['/dashboard']" routerLinkActive="pure-menu-selected"  (mouseover)="hover = 'home'" (mouseout)="hover = null">
            <img src="../../../assets/house.svg" class="top-bar-icon" alt="home">
            <span class="menu-text">DASHBOARD</span>
          </a>
        </li>
        <li>
          <a class="pure-menu-link" [routerLink]="['/team']" routerLinkActive="pure-menu-selected" (mouseover)="hover = 'team'" (mouseout)="hover = null">
            <img src="../../../assets/user.svg" class="top-bar-icon" alt="user">
            <span class="menu-text">TEAM</span>
          </a>
        </li>
        <li>
          <a class="pure-menu-link" [routerLink]="['/notifications']" routerLinkActive="pure-menu-selected" (mouseover)="hover = 'notifications'" (mouseout)="hover = null">
            <img src="../../../assets/notification.svg" class="top-bar-icon" alt="notifications">
            <span class="menu-text">NOTIFICATIONS</span>
          </a>
        </li>
        <li>
          <a class="pure-menu-link" [routerLink]="['/settings']" routerLinkActive="pure-menu-selected" (mouseover)="hover = 'settings'" (mouseout)="hover = null">
            <img src="../../../assets/settings.svg" class="top-bar-icon" alt="settings">
            <span class="menu-text">SETTINGS</span>
          </a>
        </li>
        <li>
          <a class="pure-menu-link" *ngIf="isAdmin" [routerLink]="['/admin']" routerLinkActive="pure-menu-selected" (mouseover)="hover = 'admin'" (mouseout)="hover = null">
            <img src="../../../assets/user-lock-solid.svg" class="top-bar-icon" alt="settings">
            <span class="menu-text">ADMIN</span>
          </a>
        </li>
        <li>
          <a class="pure-menu-link" (click)="logout()" (mouseover)="hover = 'logout'" (mouseout)="hover = null">
            <img class="logout-svg" src="../../../assets/sign-out-alt-solid.svg" class="top-bar-icon" alt="logout">
            <span class="menu-text">LOGOUT</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  </header>
  `,
  styleUrls: ['./topbar.component.css']
})
export class TopBarComponent implements OnInit {
  public openDropDown: boolean;
  public hover: string;

  @ViewChild('menu', { static: true }) menu: ElementRef;
  @ViewChild('toggle') toggle: ElementRef;
  @ViewChildren('menuCanTransform') menuCanTransform: QueryList<ElementRef>;
  public isAdmin: boolean;

  constructor(private auth: AuthService, private user: UserService) {
    this.openDropDown = false;
    this.hover = null;
  }

  ngOnInit() {
    this.user.isAdmin().then(isAdmin => this.isAdmin = isAdmin).catch(() => this.isAdmin = false);
  }

  toggleHorizontal(): void {
    this.menuCanTransform.forEach((element) => {
      element.nativeElement.classList.toggle('pure-menu-horizontal');
    });
  }

  @HostListener('window:resize')
  onResize() {
    if (this.menu.nativeElement.classList.contains('open')) {
      this.toggleMenu();
    }
  }

  toggleMenu(): void {
    this.toggleHorizontal();
    this.openDropDown = !this.openDropDown;
  }

  logout(): void {
    this.auth.logout();
  }
}
